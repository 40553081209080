<template>
    <div class="add-new-image">
        <resource-card>
            <template v-slot:image>
                <div class="placeholder_image" />
            </template>

            <template v-slot:primary-button>
                <div class="form-group-multiselect image-types">
                    <multiselect
                        v-model="selectedType"
                        :options="imageTypes"
                        deselect-label="Can't remove this value"
                        track-by="name"
                        label="name"
                        placeholder="Select image type"
                    />
                    <multiselect
                        v-if="selectedTypeSlug == 'motherboard_cooler_render'"
                        v-model="selectedCooler"
                        :options="coolersList"
                        deselect-label="Can't remove this value"
                        track-by="name"
                        label="name"
                        placeholder="Select cooler"
                    />
                </div>
            </template>

            <template v-slot:secondary-button>
                <input
                    id="file"
                    :disabled="!selectedType"
                    class="input-file"
                    type="file"
                    name="file"
                    @change="(event) => $emit('new-clicked', event)"
                >
                <label class="input-file-label" for="file" :class="{ 'deactivated': isDeactivated }">
                    New
                </label>
            </template>
        </resource-card>
    </div>
</template>

<script>

import ResourceCard from "@c/resource-card"

export default {
    name: "AddNewImage",
    components: {
        ResourceCard
    },
    props: {
        imageTypes: {
            type: Array,
            default() {
                return [];
            }
        },
        coolersList: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            selectedCooler: null,
            selectedType: null
        }
    },
    computed: {
        isDeactivated() {
            let isActive = true;

            if (this.selectedTypeSlug == "motherboard_cooler_render") {
                isActive = !this.selectedCooler;
            } else {
                isActive = !this.selectedType;
            }

            return isActive
        },
        selectedTypeSlug() {
            return this.selectedType && this.selectedType.slug;
        }
    },
    watch: {
        selectedCooler(value) {
            this.$emit("cooler-changed", value);
        },
        selectedType(value) {
            if (value.slug != "motherboard_cooler_render") {
                this.selectedCooler = null;
                this.$emit("cooler-changed", null);
            }
            this.$emit("type-changed", value);
        }
    }
}
</script>

<style lang="scss">

.add-new-image {
    .placeholder_image {
        background-color: rgb(201, 201, 201);
        width: 150px;
        height: 150px;
        border-radius: 3px;
    }

    .input-file {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;

    }

    .input-file-label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin-right: 10px;
        height: 40px;
        width: 70px;
        background: transparent;
        border: 1px solid rgb(110, 110, 110);
        color: gray;
        border-radius: 3px;
        font-weight: 500;
        cursor: pointer;


        &:hover {
            background-color: rgb(201, 201, 201);
            border: 0;
            color: white;
        }
    }

    .image-types {
        height: 16px;
        margin-right: 10px;
    }
}

</style>
