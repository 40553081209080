<template>
    <custom-fields-form
        ref="attributesForm"
        :form-fields="fieldsSchema"
        :form-options="formOptions"
        form-name="attributesForm"
        @form-submitted="(data) => $emit('form-submitted', data)"
    />
</template>

<script>
import _chunk from "lodash/chunk";

export default {
    props: {
        attributes: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            formOptions: {
                buttons: {
                    submit: {
                        text: "Save Attributes",
                        class: "btn btn-primary"
                    }
                },
                actionsWrapperClass: "d-flex justify-content-end mt-2 mr-3"
            },
            fieldsSchema: []
        }
    },
    watch: {
        attributes: {
            handler(value) {
                this.generateAttributesSchema(value);
            },
            immediate: true
        }
    },
    methods: {
        getTextField(data) {
            return {
                field: data.slug,
                label: data.title,
                type: "text",
                value: data.value || "",
                attributes: {
                    class: {
                        "form-control": true
                    }
                },
                wrapperAttributes: {
                    class: {
                        "form-group": true,
                        "form-group-default": true
                    }
                }
            }
        },
        generateAttributesSchema(attributes) {
            const fields = attributes.map((attribute) => this.getTextField(attribute));
            this.fieldsSchema = _chunk(fields, 2);
        }
    }
}
</script>

<style>
form[data-vv-scope="attributesForm"] {
    width: 100%;
}
</style>
