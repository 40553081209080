<template>
    <transition name="fade">
        <form class="resource-form" @submit.prevent>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="form-group form-group-default">
                        <label class="form-label">Max Quantity:</label>
                        <input
                            v-model="formData.max_quantity"
                            name="max-quantity"
                            class="form-control"
                            type="number"
                            min="0"
                        >
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label class="form-label switch-label">Is Published:</label>
                    <switches
                        v-model="formData.is_published"
                        class="published-switch"
                        :color="formData.is_published ? 'green' : 'default'"
                        theme="bulma"
                    />
                </div>
            </div>
            <form-actions
                :is-loading="false"
                @save="submitProductVariants"
                @cancel="$emit('cancel')"
            >
                <template v-slot:submit-label>
                    Save Variant
                </template>
            </form-actions>

            <div v-if="attributes.length">
                <h4 class="mt-4 mb-4">
                    Attributes
                </h4>
                <attributes
                    :attributes="attributes"
                    @form-submitted="onAttributesFormSubmitted"
                />
            </div>
        </form>
    </transition>
</template>

<script>
import Switches from "vue-switches";
import FormActions from "@c/form-actions.vue";
import { mapState } from "vuex";
import formsMixin from "@m/formsMixin.js";
import attributeFormMixin from "@m/attributeFormMixin.js";
import Attributes from "../attributes.vue";

export default {
    name: "BLDVariantForm",
    components: {
        Switches,
        FormActions,
        Attributes
    },
    mixins: [
        formsMixin,
        attributeFormMixin
    ],
    props: {
        variant: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            formData: {
                is_published: false,
                max_quantity: 0
            },
            submitMethod: "PUT",
            attributes: [],
            shouldToggleForm: false
        }
    },
    computed: {
        ...mapState({
            currentLocale: state => state.Locale.currentLocale.locale
        }),
        name: {
            get() {
                return this.formData.name[this.currentLocale];
            },
            set(value) {
                Vue.set(this.formData.name, this.currentLocale, value);
            }
        },
        submitUrl() {
            const {
                region_id: rId,
                product_id: pId,
                product_variant_id: vId
            } = this.formData;

            return `/builder/regions/${rId}/products/${pId}/variants/${vId}`;
        },
        attributeEndpoint() {
            const {
                product_id: productId,
                product_variant_id: variantId
            } = this.formData;
            return `/products/${productId}/variants/${variantId}/attributes`;
        }
    },
    watch: {
        variant: {
            handler() {
                this.formData = this.prepareVariantFormFields(this.variant);
                this.fetchAttributes();
            },
            immediate: true
        }
    },
    methods: {
        submitProductVariants() {
            this.$_submitResource(this.prepareVariantFormFields, this.onSuccess);
        },
        onSuccess() {
            this.$notify({
                text: `Form was saved successfully.`,
                type: "success"
            });

            this.$store.dispatch("BLDForm/updateVariants");
            this.$emit("form-saved");
        },
        prepareVariantFormFields(data) {
            return {
                ...data,
                is_published: Number(data.is_published),
                is_oversellable: Number(data.is_oversellable),
                is_default: Number(data.is_default)
            };
        },
        fetchAttributes() {
            axios(this.attributeEndpoint).then(({ data }) => {
                this.attributes = data;
            });
        },
        onAttributesFormSubmitted(data) {
            this.$store.commit("Application/SET_IS_LOADING", true);

            const attributesFormData = this.$_prepareAttributes(data);
            axios({
                url: this.attributeEndpoint,
                method: "PUT",
                data: attributesFormData
            }).then(() => {
                this.$notify({
                    text: `Attributes form saved successfully.`,
                    type: "success"
                });
            }).catch((error) => {
                this.$notify({
                    text: error.response.data.errors.message,
                    type: "error"
                });
            }).finally(() => {
                this.$store.commit("Application/SET_IS_LOADING", false);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.resource-form {
    .published-switch {
        margin-bottom: 0;
        margin-left: 70px;
    }

    .switch-label {
        width: 118px;
    }
}
</style>

<style lang="scss" src="@/assets/scss/resource.scss" />
