<template>
    <div class="image-item">
        <resource-card>
            <template v-slot:image>
                <img :src="image.url">
            </template>
            <template v-slot:label>
                <span>
                    <template v-if="image.field_name == 'motherboard_cooler_render'">
                        <p>
                            <strong>
                                {{ imageCoolerName }}
                            </strong>
                        </p>
                    </template>
                    <strong>
                        {{ imageTypeName }}
                    </strong>
                </span>
            </template>
            <template v-slot:secondary-button>
                <delete-button :delete-title="`'${image.name}'`" @delete-confirmed="$emit('delete-clicked')" />
            </template>
        </resource-card>
    </div>
</template>


<script>
import ResourceCard from "@c/resource-card"
import DeleteButton from "@c/delete-button";

export default {
    name: "ImagePreview",
    components: {
        ResourceCard,
        DeleteButton
    },
    props: {
        coolersList: {
            type: Array,
            default() {
                return [];
            }
        },
        imageTypes: {
            type: Array,
            default() {
                return [];
            }
        },
        image: {
            type: Object,
            required: true
        }
    },
    computed: {
        imageTypeName() {
            const imageType = this.imageTypes.find((type) => type.slug === this.image.field_name);
            const name = imageType && imageType.name;
            return name || this.image.field_name;
        },
        imageCoolerName() {
            if (this.image.attributes && this.image.attributes.cooler_product) {
                const coolerProduct = JSON.parse(this.image.attributes.cooler_product);
                const { name = "" } = this.coolersList.find((cooler) => cooler.products_id === coolerProduct.products_id) || {};
                return name;
            }
            return "";
        }
    }
}
</script>

<style lang="scss">
.image-item {
    img {
        width: 150px;
        height: 150px;
        object-fit: contain;
        object-position: center;
    }

}
</style>

