<template>
    <form class="resource-form store-form" @submit.prevent>
        <div class="row">
            <div class="col">
                <h3 class="title">
                    Product Options on Store
                </h3>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label">Catalog Category</label>
                <multiselect
                    v-model="selectedCatalogCategory"
                    :options="catalogCategories"
                    :allow-empty="false"
                    deselect-label="Can't remove this value"
                    track-by="name"
                    label="name"
                />
            </div>
        </div>

        <div v-if="showPublishedToggle" class="row">
            <div class="col-6">
                <label class="form-label switch-label">Published:</label>
                <switches
                    v-model="isPublished"
                    name="published"
                    class="published-switch"
                    :color="formData.is_published ? 'green' : 'default'"
                    theme="bulma"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label switch-label">Is Best Seller:</label>
                <switches
                    v-model="isBestSeller"
                    name="published"
                    class="published-switch"
                    :color="formData.is_best_seller ? 'green' : 'default'"
                    theme="bulma"
                />
            </div>
        </div>

        <div class="row mb-5">
            <div class="col-6">
                <label class="form-label switch-label">Show on Product Catalog:</label>
                <switches
                    v-model="showInProductList"
                    name="published"
                    class="published-switch"
                    :color="formData.is_published_in_product_list ? 'green' : 'default'"
                    theme="bulma"
                />
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-6">
                <label class="form-label switch-label">CPU Type:</label>
                <input
                    v-model="cpuType"
                    name="cpu_type"
                    class="form-control"
                    type="text"
                    min="0"
                >
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-6">
                <label class="form-label switch-label">GPU Type:</label>
                <input
                    v-model="gpuType"
                    name="gpu_type"
                    class="form-control"
                    type="text"
                    min="0"
                >
            </div>
        </div>

        <div class="row mb-5">
            <div class="col-6">
                <label class="form-label switch-label">Sub Title:</label>
                <input
                    v-model="subtitle"
                    name="subtitle"
                    class="form-control"
                    type="text"
                    min="0"
                >
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label">Social Brands</label>
                <multiselect
                    v-model="seleted"
                    :options="options"
                    :allow-empty="false"
                    deselect-label="Can't remove this value"
                    track-by="value"
                    label="label"
                />
            </div>
        </div>

        <form-actions
            :is-loading="false"
            @save="onSaveForm"
        >
            <template v-slot:cancel-button>
                <span />
            </template>
            <template v-slot:submit-label>
                Save Product
            </template>
        </form-actions>

        <div v-if="variants.length">
            <div class="row">
                <div class="col">
                    <h3 class="title">
                        Variant Options on Store
                    </h3>
                </div>
            </div>
            <store-variants locale="en_US" :variants="variants" />
        </div>
    </form>
</template>

<script>
import Switches from "vue-switches";
import StoreVariants from "./variants";
import FormActions from "@c/form-actions.vue";
import { mapState, mapGetters } from "vuex";
import formsMixin from "@m/formsMixin";
import { isJson } from "@/utils/helpers";


// TODO: Propose to use pagination with the Vue Multiselect component
export default {
    name: "StoreForm",
    components: {
        Switches,
        StoreVariants,
        FormActions
    },
    mixins: [formsMixin],
    data() {
        return {
            submitMethod: "PUT",
            productId: this.$route.params.id,
            brands: [
                "AMD",
                "AORUS",
                "ASUS",
                "ASROCK",
                "EVGA",
                "GIGABYTE",
                "G SKILL",
                "Kingston",
                "Intel",
                "MSI",
                "NZXT",
                "PNY",
                "Seasonic",
                "TeamGroup",
                "Western Digital"
            ],
            catalogCategories: []
        }
    },
    computed: {
        ...mapState({
            formData: state => state.StoreForm.formData,
            variants: state => state.StoreForm.variants,
            currentRegionId: state => state.Region.currentRegionId,
            currentLocale: state => state.Locale.currentLocale.locale
        }),
        ...mapGetters({
            preparedFormData: "StoreForm/preparedFormData",
            userRoles: "User/userRoles",
            selectOptions: "StoreForm/getSelectOptions"
        }),
        showPublishedToggle() {
            return this.userRoles.includes("Admins");
        },
        submitUrl() {
            return `/regions/${this.currentRegionId}/products/${this.productId}`;
        },
        fetchUrl() {
            return `/regions/${this.currentRegionId}/products/${this.productId}`
        },
        name: {
            get() {
                return this.formData.name;
            },
            set(value) {
                this.$store.commit("StoreForm/SET_NAME", value);
            }
        },
        cpuType: {
            get() {
                return this.formData.cpu_type;
            },
            set(value) {
                this.$store.commit("StoreForm/SET_CPU_TYPE", value);
            }
        },
        gpuType: {
            get() {
                return this.formData.gpu_type;
            },
            set(value) {
                this.$store.commit("StoreForm/SET_GPU_TYPE", value);
            }
        },
        isPublished: {
            get() {
                return this.formData.is_published;
            },
            set(value) {
                this.$store.commit("StoreForm/SET_IS_PUBLISHED", value);
            }
        },
        isBestSeller: {
            get() {
                return this.formData.is_best_seller;
            },
            set(value) {
                this.$store.commit("StoreForm/SET_IS_BEST_SELLER", value);
            }
        },
        selectedCatalogCategory: {
            get() {
                const { category_id: selectedCategoryId } = this.formData;
                const category = this.catalogCategories.find(({ id }) => id == selectedCategoryId) || "";

                return category;
            },
            set(value) {
                this.$store.commit("StoreForm/SET_CATEGORY_ID", value.id);
            }
        },
        options: {
            get() {
                return this.selectOptions;
            },
            set(value) {
                this.$store.commit("StoreForm/SET_STORE_BRAND", value.label);
            }
        },
        seleted: {
            get() {
                const storeBrand = this.formData.store_brand;
                return this.selectOptions.find(({ label }) => label === storeBrand);
            },
            set(value) {
                this.$store.commit("StoreForm/SET_STORE_BRAND", value.label);
            }
        },
        showInProductList: {
            get() {
                return this.formData.is_published_in_product_list;
            },
            set(value) {
                this.$store.commit("StoreForm/SET_SHOW_IN_PRODUCT_LIST", value);
            }
        },
        subtitle: {
            get() {
                return this.formData.subtitle;
            },
            set(value) {
                this.$store.commit("StoreForm/SET_SUB_TITLE", value);
            }
        },
    },
    created() {
        if (this.isEditing) {
            this.$_fetchResource(this.onFetchSuccess);
        }

        this.setCatalogCategories();
    },
    beforeDestroy() {
        this.$store.dispatch("StoreForm/clean");
    },
    methods: {
        async onFetchSuccess(storeProduct) {
            this.$store.commit("StoreForm/SET_FORM_DATA", storeProduct);
            this.$store.dispatch("StoreForm/parseData");
            this.$store.dispatch("StoreForm/updateVariants");
        },
        onSaveForm() {
            this.$_submitResource(this.prepareStoreProductData);
        },
        prepareStoreProductData(storeProduct) {
            return {
                ...storeProduct,
                is_published: Number(storeProduct.is_published),
                is_best_seller: Number(storeProduct.is_best_seller),
                is_published_in_product_list: Number(storeProduct.is_published_in_product_list),
                cpu_type: storeProduct.cpu_type,
                gpu_type: storeProduct.gpu_type,
                store_brand: storeProduct.store_brand,
                subtitle: storeProduct.subtitle
            };
        },
        async setCatalogCategories() {
            const categories = await this.fetchCatalogCategories();
            const parsedName = (name) => isJson(name) ? this.checkCurrentLocale(name) : "";

            const parsedCategories = categories.map(category => {
                return { ...category, name: parsedName(category.name) }
            })

            this.catalogCategories = parsedCategories;
            const shouldSetCatalog = this.formData.category_id === undefined || this.formData.category_id === "";

            if (shouldSetCatalog) {
                this.setSelectedCatalogCategory();
            }
        },
        async fetchCatalogCategories() {
            try {
                const { data: response } = await axios("categories?limit=200");
                return response;
            } catch (error) {
                this.$notify({
                    text: error.response.data.errors.message,
                    type: "error"
                });
            }
        },
        setSelectedCatalogCategory() {
            const [firstCategory = { id: "" }] = this.catalogCategories || [];
            this.selectedCatalogCategory = firstCategory;
        },
        checkCurrentLocale(name) {
            return JSON.parse(name)[this.currentLocale] ?
                JSON.parse(name)[this.currentLocale] : JSON.parse(name)["en_US"];
        }
    }
}
</script>

<style lang="scss">
.store-form {
    .published-switch {
        margin: 20px 15px 0 70px;
    }

    .switch-label {
        width: 158px;
    }
}
</style>
