<template>
    <div class="foldable-resource-card">
        <div class="card">
            <slot v-if="shouldShowImage" name="image">
                <div class="placeholder-image" />
            </slot>
            <span class="label">
                <slot name="label" />
            </span>
            <div class="button-group">
                <slot name="primary-button">
                    <button type="button" @click="() => showContent = !showContent">
                        <slot name="primary-button-label">
                            Edit
                        </slot>
                    </button>
                </slot>
                <slot name="secondary-button" />
            </div>
        </div>
        <div v-if="showContent" class="foldable-content">
            <slot name="content" />
        </div>
    </div>
</template>

<script>
export default {
    name: "FoldableResourceCard",
    props: {
        toggleContent: {
            type: Boolean,
            default: false
        },
        shouldShowImage: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            showContent: false
        }
    },
    watch: {
        toggleContent() {
            this.showContent = !this.showContent;
        }
    }
}
</script>

<style lang="scss">

.foldable-resource-card {
    background-color: #f0f0f0;

    img {
        max-width: 72px;
    }

    .card {
        display: flex;
        border: 0.5px solid rgba(184, 184, 184, 0.849);
        background-color: #F7F7F7;
        padding: 10px;
        justify-content: space-between;
        width: 100%;
        flex-direction: row;

        .label {
            display: flex;
            align-items: center;
            flex-basis: 50%;
        }

        .button-group {
            display: flex;
            align-items: center;

            button {
                margin-right: 10px;
                height: 40px;
                width: 70px;
                background: transparent;
                border: 1px solid rgb(110, 110, 110);
                border-radius: 3px;
                font-weight: 500;
                cursor: pointer;

                &:hover {
                    background-color: rgb(201, 201, 201);
                    border: 0;
                    color: white;
                }
            }

            button:focus {
                outline-color: rgba(121, 121, 121, 0.884);
            }
        }

        .placeholder-image {
            background-color: rgb(201, 201, 201);
            width: 72px;
            height: 72px;
            border-radius: 3px;
            cursor: pointer;
        }
    }

    .foldable-content {
        padding: 6px;
    }
}
</style>
