<template>
    <div class="image-list row">
        <div v-for="image in images" :key="image.id" class="col-12 col-lg-6">
            <file-preview :file="image" @delete-clicked="deleteImage(image.id)" />
        </div>
        <div class="new-image-container col-12 col-lg-6">
            <file-uploader
                :reset="reset"
                uploader-id="image-uploader"
                :file-types="imageTypes"
                @new-clicked="newImageClicked"
                @type-changed="(type) => imageType = type.slug"
            />
        </div>
    </div>
</template>

<script>

import FilePreview from "@c/file-preview";
import { mapState } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import FileUploader from "@c/file-uploader";

export default {
    name: "ProductImageList",
    components: {
        FilePreview,
        FileUploader
    },
    props: {
        images: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            imageType: "",
            imageTypes: [],
            reset: false
        }
    },
    computed: {
        ...mapState({
            productFormData: state => state.ProductForm.formData
        })
    },
    mounted() {
        this.getImageTypes();
    },
    methods: {
        async newImageClicked(event) {
            const image = event.target.files[0];
            this.$store.commit("Application/SET_IS_LOADING", true);

            try {
                const formData = new FormData();
                formData.append(this.imageType, image);
                formData.append("name", image.name);

                await axios({
                    method: "POST",
                    url: "/filesystem",
                    data: formData
                }).then(({ data: imageFile }) => {
                    this.$emit("image-uploaded", { image: imageFile[0], field_name: this.imageType });
                })

                this.reset = true;
            } catch (e) {
                console.error(e);
            } finally {
                this.$store.commit("Application/SET_IS_LOADING", false);
            }
        },
        deleteImage(id) {
            const clonedFiles = cloneDeep(this.productFormData.files);
            const filteredFiles = clonedFiles.filter((file => file.id != id));
            this.$store.commit("ProductForm/SET_FILES", filteredFiles);
        },
        getImageTypes() {
            axios({
                method: "GET",
                url: "/media-types"
            }).then(({ data }) => {
                this.imageTypes = data;
            })
        }
    }
}
</script>

<style lang="scss">
.row {
    margin: 0px;
}

.image-list {
    padding: 12px;

    .new-image-container {
        width: 150px;
    }
}
</style>
