<template>
    <div class="add-new-image">
        <resource-card>
            <template v-slot:image>
                <div class="placeholder_image" />
            </template>

            <template v-if="fileTypes.length" v-slot:primary-button>
                <div class="form-group-multiselect image-types">
                    <multiselect
                        v-model="selectedType"
                        :options="fileTypes"
                        deselect-label="Can't remove this value"
                        track-by="name"
                        label="name"
                        placeholder="Select type"
                    />
                </div>
            </template>

            <template v-slot:secondary-button>
                <input
                    :id="uploaderId"
                    class="uploader-input"
                    :disabled="shouldDisableNewUpload"
                    :class="uploaderId"
                    type="file"
                    @change="(event) => $emit('new-clicked', event)"
                >
                <label class="new-label" :for="uploaderId" :class="{ 'deactivated': shouldDisableNewUpload }">
                    New
                </label>
            </template>
        </resource-card>
    </div>
</template>

<script>

import ResourceCard from "@c/resource-card"

export default {
    name: "FileUploader",
    components: {
        ResourceCard
    },
    props: {
        reset: {
            type: Boolean,
            default: false
        },
        fileTypes: {
            type: Array,
            default: () => {
                return [];
            }
        },
        uploaderId: {
            type: String,
            default: "input-product-file"
        }
    },
    data() {
        return {
            selectedType: null
        }
    },
    computed: {
        shouldDisableNewUpload() {
            return Boolean(this.fileTypes.length && !this.selectedType);
        }
    },
    watch: {
        selectedType(value) {
            this.$emit("type-changed", value)
        },
        reset() {
            this.$nextTick(() => {
                document.querySelector(".uploader-input").value = null;
            })
        }
    }
}
</script>

<style lang="scss">
.add-new-image {
    .placeholder_image {
        background-color: rgb(201, 201, 201);
        width: 150px;
        height: 150px;
        border-radius: 3px;
    }

    .uploader-input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    .new-label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin-right: 10px;
        height: 40px;
        width: 70px;
        background: transparent;
        border: 1px solid rgb(110, 110, 110);
        color: gray;
        border-radius: 3px;
        font-weight: 500;
        cursor: pointer;


        &:hover {
            background-color: rgb(201, 201, 201);
            border: 0;
            color: white;
        }
    }

    .image-types {
        height: 16px;
        margin-right: 10px;
    }
}

</style>
