<template>
    <div class="resource-form build-form">
        <div class="row">
            <div class="col-12">
                <h3 class="title">
                    <h1>Recommended Builds</h1>
                </h3>
            </div>
        </div>

        <div class="row mb-3">
            <div class="details">
                <span>
                    <strong>ID:</strong> {{ buildId }}
                </span>
                <span>
                    <strong>Price:</strong> {{ price }}
                </span>
                <span>
                    <strong>Cpu:</strong> {{ cpu }}
                </span>
            </div>
        </div>

        <div class="row">
            <div class="col-3">
                <language-locale-field />
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                <div :class="{ 'border-danger': errors.has('name') }" class="form-group form-group-default">
                    <label :class="{'text-danger': errors.has('name') }">
                        Build Name
                        <span v-if="errors.has('name')">(required)</span>
                    </label>
                    <input
                        v-model="name"
                        v-validate="'required'"
                        name="name"
                        data-vv-validate-on="blur"
                        class="form-control"
                        type="text"
                        autofocus
                    >
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <div :class="{ 'border-danger': errors.has('description') }" class="form-group form-group-default">
                    <label :class="{'text-danger': errors.has('description') }" class="form-label">
                        Build Description
                        <span v-if="errors.has('description')">(required)</span>
                    </label>
                    <input
                        v-model.trim="description"
                        v-validate="'required'"
                        name="description"
                        data-vv-validate-on="blur"
                        class="form-control"
                        type="text"
                    >
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group-multiselect">
                    <label class="form-label">Types</label>
                    <multiselect
                        v-model="seleted"
                        :options="options"
                        :allow-empty="false"
                        deselect-label="Can't remove this value"
                        track-by="value" 
                        label="label"
                        @input="handleSelectionChange"
                    />
                </div>
            </div>
            <div v-if="seleted.value == 7 && showPublishedToggle" class="col-sm-12 col-md-6 col-lg-6">
                <ValidationProvider v-slot="{ errors }" rules="required">
                    <div :class="{ 'border-danger': errors[0] }" class="form-group form-group-default">
                        <label :class="{ 'text-danger': errors[0] }" class="form-label">
                            Slug
                            <span v-if="errors[0]">(required)</span>
                        </label>
                        <div class="slug-container">
                            <input
                                v-model="slugName"
                                name="slugName"
                                data-vv-validate-on="blur"
                                class="form-control "
                                type="text"
                                :disabled="showPublishedToggle == 0"
                            >
                            <button v-if="showPublishedToggle" class="generate-slug-btn" @click.prevent="generateSlug">
                                Generate
                            </button>
                        </div>
                    </div>
                </validationprovider>
            </div>
        </div>

        <div v-if="seleted.value == 7 && slugName.length" class="slug-link">
            <label>
                Slug Link
            </label>
            <span>
                https://Nzxt.com/build/locked/{{ slugName }}
            </span>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label switch-label">Is Published:</label>
                <switches
                    v-model="isPublished"
                    name="published"
                    class="published-switch"
                    :color="isPublished ? 'green' : 'default'"
                    theme="bulma"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label switch-label">Is From Product:</label>
                <switches
                    v-model="isFromProduct"
                    name="published"
                    class="published-switch"
                    :color="isFromProduct ? 'green' : 'default'"
                    theme="bulma"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <image-uploader
                    :files="buildFiles"
                    type="cover"
                    @detele-image="onDeteleImage"
                    @image-uploaded="onImageUploaded"
                />
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-12">
                <h3 class="title part-details">
                    <h1>Parts Details</h1>
                    <button
                        class="btn btn-primary"
                        type="button"
                        @click="addNewPart"
                    >
                        Add New Part
                    </button>
                </h3>
            </div>
        </div>

        <div>
            <parts-details
                :parts="parts"
                :build-parts-details="buildPartsDetails"
                :parts-categories="partsCategories"
                :parts-variants="partsVariants"
                :is-loading="isLoading"
            />
        </div>

        <div class="row mt-5">
            <div class="col-12">
                <button
                    class="btn btn-success btn-lg btn-block"
                    type="button"
                    @click="updateOrCreateBuild"
                >
                    Update
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { ValidationProvider } from "vee-validate";
import { composeResources } from "@/utils/helpers";
import _isEmpty from "lodash/isEmpty";
import Switches from "vue-switches";
import LanguageLocaleField from "@c/language-locale-field";
import ImageUploader from "@c/image-uploader"
import PartsDetails from "./parts-details";

export default {
    name: "BuildForm",
    components: {
        LanguageLocaleField,
        PartsDetails,
        Switches,
        ValidationProvider,
        ImageUploader
    },
    computed: {
        ...mapState({
            resources: state => composeResources(state.Application.resources),
            currentLocale: state => state.Locale.currentLocale.locale,
            currentRegionId: state => state.Region.currentRegionId
        }),
        ...mapGetters({
            getIsLoading: "BuildForm/getIsLoading",
            getbuildFormData: "BuildForm/getbuildFormData",
            getParts: "BuildForm/getParts",
            getPartsVariants: "BuildForm/getPartsVariants",
            getPartsCategories: "BuildForm/getPartsCategories",
            selectOptions: "BuildForm/getSelectOptions",
            userRoles: "User/userRoles"
        }),
        isLoading() {
            return this.getIsLoading;
        },
        showPublishedToggle() {
            return this.userRoles.includes("Admins");
        },
        name: {
            get() {
                return this.getbuildFormData.name[this.currentLocale];
            },
            set(value) {
                this.changeBuildDataLocale({
                    property: "name",
                    value,
                    locale: this.currentLocale
                });
            }
        },
        description: {
            get() {
                return this.getbuildFormData.description[this.currentLocale];
            },
            set(value) {
                this.changeBuildDataLocale({
                    property: "description",
                    value,
                    locale: this.currentLocale
                });
            }
        },
        isPublished: {
            get() {
                return this.getbuildFormData.is_published;
            },
            set(value) {
                this.changeBuildData({ property: "is_published", value });
            }
        },
        slugName: {
            get() {
                return this.getbuildFormData.slug;
            },
            set(value) {
                this.$store.commit("BuildForm/SET_SLUG", value);
            }
        },
        options: {
            get() {
                return this.selectOptions;
            },
            set(value) {
                this.$store.commit("BuildForm/SET_OPTIONS", value.value);
            }
        },
        seleted: {
            get() {
                const id = this.getbuildFormData.builds_types_id;
                return this.selectOptions.find(({ value }) => value === id);
            },
            set(value) {
                this.$store.commit("BuildForm/SET_BUILD_TYPE", value.value);
            }
        },
        isFromProduct: {
            get() {
                return this.getbuildFormData.is_from_product;
            },
            set(value) {
                this.changeBuildData({ property: "is_from_product", value });
            }
        },
        resource() {
            return this.resources.find(resource => resource.slug == this.$route.params.resource);
        },
        resourceSlug() {
            const { slug } = this.resource;
            return slug;
        },
        buildId() {
            return this.$route.params.id;
        },
        buildPartsDetails() {
            return this.getbuildFormData.details;
        },
        buildFiles() {
            return !_isEmpty(this.getbuildFormData.files) ? this.getbuildFormData.files[0] : [];
        },
        price() {
            return this.getbuildFormData.price;
        },
        cpu() {
            return this.getbuildFormData.cpu;
        },
        parts() {
            return this.getParts;
        },
        partsCategories() {
            return this.getPartsCategories;
        },
        partsVariants() {
            return this.getPartsVariants;
        }
    },
    mounted() {
        if (!this.buildId) {
            this.clearData();
            this.fetchPartsDetails({
                currentRegionId: this.currentRegionId
            })
        } else {
            this.fetchBuidData({
                resourceSlug: this.resourceSlug,
                buildId: this.buildId,
                currentRegionId: this.currentRegionId
            });
        }
    },
    beforeDestroy() {
        this.$store.dispatch("BuildForm/clean");
    },
    methods: {
        ...mapActions({
            fetchBuidData: "BuildForm/fetchBuidData",
            fetchPartsDetails: "BuildForm/fetchPartsDetails",
            changeBuildData: "BuildForm/changeBuildData",
            changeBuildDataLocale: "BuildForm/changeBuildDataLocale",
            updateBuildParts: "BuildForm/updateBuildParts",
            updateBuildFiles: "BuildForm/updateBuildFiles",
            updateRecommendedBuild: "BuildForm/updateRecommendedBuild",
            createRecommendedBuild: "BuildForm/createRecommendedBuild",
            clearData: "BuildForm/clearData"
        }),
        generateSlug() {
            const slugGenerated = this.name ?? this.slugName;
            if (!slugGenerated) {
                return;
            }
            const newSlug = slugGenerated.toLowerCase()
                .replace(/\s+/g, "-")
                .replace(/[^\w\-]+/g, "")
                .replace(/\-\-+/g, "-")
                .replace(/^-+/, "")
                .replace(/-+$/, "");
            this.$store.commit("BuildForm/SET_SLUG", newSlug)
        },
        handleSelectionChange(selected) {
            this.$store.commit("BuildForm/SET_BUILD_TYPE", selected.value);
        },
        addNewPart() {
            const currentPartsDetails = this.buildPartsDetails;

            const newPart = {
                builder_category_id: this.partsCategories[0].id,
                product_id: 0,
                quantity: 1,
                variant_id: 0
            }

            const newPartsDetails = [newPart, ...currentPartsDetails];

            this.updateBuildParts(newPartsDetails);
        },
        updateOrCreateBuild() {
            const data = {
                ...this.getbuildFormData,
                name: JSON.stringify(this.getbuildFormData.name),
                description: JSON.stringify(this.getbuildFormData.description),
                is_published: String(Number(this.getbuildFormData.is_published)),
                is_from_product: String(Number(this.getbuildFormData.is_from_product)),
                builds_types_id: String(Number(this.getbuildFormData.builds_types_id)),
                slug: this.getbuildFormData.slug
            };

            if (_isEmpty(this.buildFiles)) {
                this.$notify({
                    title: "Build Image",
                    text: "You cannot create or update a build without an image.",
                    type: "warn"
                });

                return;
            }

            this.buildId ? this.updateRecommendedBuild(data) : this.createRecommendedBuild(data);
        },
        onImageUploaded(file) {
            const currentBuildFiles = this.buildFiles;

            const newImage = {
                field_name: file.field_name,
                url: file.image.url,
                filesystem_id: file.image.id
            };

            const newImageFiles = [newImage, ...currentBuildFiles];

            this.updateBuildFiles(newImageFiles);
        },
        onDeteleImage() {
            this.updateBuildFiles([[]]);
        }
    }
}
</script>

<style lang="scss" scoped>
.build-form {
    h1 {
        font-size: 1.5rem;
    }

    .published-switch {
        margin: 20px 15px -3px 1px;
    }

    .switch-label {
        width: 118px;
    }
}

.slug-container {
    display: flex;
}

.slug-link {
    display: flex;
    flex-direction: column;
    span {
        padding-left: 5px;
    }
}

.generate-slug-btn {
    height: 18px;
    font-size: 12px;
    padding: 1px;
    background: #f7f7f7;
    border: 0.5px solid rgba(184, 184, 184, 0.849);
    cursor: pointer;
}

.generate-slug-btn:focus {
    outline:0;
}

.details {
    display: flex;
    flex-direction: column;
}

.part-details {
    display: flex;
    justify-content: space-between;
 }
</style>
