<template>
    <div class="main">
        <h1 class="section-title">
            Users List
        </h1>

        <input
            v-model="searchQuery"
            type="text"
            class="search-input"
            placeholder="Search by email..."
            @input="debouncedSearch"
        >


        <table class="user-table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th class="action">
                        Action
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="user in userList" :key="user.id">
                    <td>{{ user.username }}</td>
                    <td>{{ user.email }}</td>
                    <td class="action">
                        <button class="btn btn-block btn-danger" @click="confirmDelete(user.id)">
                            Delete
                        </button>
                        <button class="btn btn-primary" @click="downloadUser(user.id)">
                            Download
                        </button>
                        <button class="btn btn-secondary" @click="showMerge(user.id)">
                            Merge
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import ResourceDeleteModal from "@c/modals/resource-delete-modal";
import mergeModal from "@c/modals/merge-modal.vue";
export default {
    name: "UsersList",
    data() {
        return {
            userList: [],
            searchQuery: "",
            debounceTimeout: null
        };
    },
    created() {
        this.fetchUsers();
    },
    methods: {
        debouncedSearch() {
            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = setTimeout(() => {
                this.fetchUsers();
            }, 500);
        },
        fetchUsers() {
            let url = "/cs-users"
            if (this.searchQuery) {
                url += `?q=(email:${this.searchQuery})`;
            }
            axios.get(url)
                .then(response => {
                    this.userList = response.data;
                })
                .catch(error => {
                    console.error("Error fetching users:", error);
                });
        },
        confirmDelete(userId) {
            this.$modal.show(ResourceDeleteModal, {
                buttons: [{
                    title: "Cancel",
                    handler: () => {
                        this.$modal.hide("delete-resource");
                    }
                }, {
                    title: "Confirm",
                    class: "btn-danger",
                    handler: () => {
                        this.deleteUser(userId);
                        this.$modal.hide("delete-resource");
                    }
                }],
                deleteEntity: "this"
            }, {
                adaptive: true,
                clickToClose: false,
                height: "auto",
                name: "delete-resource",
                width: 500
            });
        },
        showMerge(userId) {
            this.$modal.show(mergeModal, {
                inputs: [{
                    model: true,
                    class: "search-input",
                    placeholder: "Order number"
                }],
                userId: userId
            }, {
                adaptive: true,
                clickToClose: false,
                height: "auto",
                name: "send-merge",
                width: 500
            });
        },
        deleteUser(userId) {
            axios.delete(`/cs-users/${userId}`)
                .then(() => {
                    this.userList = this.userList.filter(user => user.id !== userId);
                })
                .catch((error) => {
                   console.error({ error });
                });
        },
        downloadUser(userId) {
            axios({
                url: `/users/${userId}/download-gdpr/`,
                method: "POST",
                responseType: "blob" // Important for dealing with binary files
            })
                .then(response => {
                // Create a URL from the blob
                    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    // Create a temporary link element
                    const fileLink = document.createElement("a");
                    // Set the download attribute of the link to the filename you wish to use
                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", "gdpr-data.zip"); // You can dynamically set the file name if the API sends it
                    // Append the link to the body
                    document.body.appendChild(fileLink);
                    // Programmatically click the link to trigger the download
                    fileLink.click();
                    // Remove the link after triggering download
                    fileLink.remove();
                    // Clean up the URL object
                    window.URL.revokeObjectURL(fileURL);
                })
                .catch(error => {
                    console.error("Error downloading user data:", error);
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.main {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.user-table {
  width: 100%;
  border-collapse: collapse;
}

.user-table th, .user-table td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
}

.user-table th {
  background-color: #f2f2f2;
}
.section-title {
    font-size: 32px;
    letter-spacing: 0;
    font-weight: 600;
    color: #000;
    margin: 1rem;
}

.search-input {
  margin-bottom: 10px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  @media only screen and (min-width: 500px) {
    width: 25%
  }
}
button {
    width: 100%;
}
.action {
    display: flex;
    flex-direction: row;
    gap: 20px;
    @media only screen and (min-width: 500px) {
        width: 500px
     }
}
</style>
