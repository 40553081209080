<template>
    <div class="resource-form">
        <div class="row">
            <div class="col-12">
                <h3 class="title">
                    <h1>Game</h1>
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                <div :class="{ 'border-danger': errors.has('name') }" class="form-group form-group-default">
                    <label :class="{'text-danger': errors.has('name') }">
                        Name
                        <span v-if="errors.has('name')">(required)</span>
                    </label>
                    <input
                        v-model="game.name"
                        v-validate="'required'"
                        name="name"
                        data-vv-validate-on="blur"
                        class="form-control"
                        type="text"
                        autofocus
                    >
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <div :class="{ 'border-danger': errors.has('description') }" class="form-group form-group-default">
                    <label :class="{'text-danger': errors.has('description') }" class="form-label">
                        Short Name (Abbreviated)
                        <span v-if="errors.has('description')">(required)</span>
                    </label>
                    <input
                        v-model="game.store_name"
                        v-validate="'required'"
                        name="description"
                        data-vv-validate-on="blur"
                        class="form-control"
                        type="text"
                    >
                </div>
            </div>
        </div>
        <div class="row game-switch">
            <div class="col-6">
                <label class="form-label switch-label">Is Published:</label>
                <switches
                    v-model="game.is_published"
                    name="published"
                    :color="game.is_published ? 'green' : 'default'"
                    class="published-switch"
                    theme="bulma"
                />
            </div>
            <div class="col-6">
                <label class="form-label switch-label">V-sync:</label>
                <switches
                    v-model="game.vsync"
                    name="published"
                    class="published-switch"
                    :color="game.vsync ? 'green' : 'default'"
                    theme="bulma"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <p class="image_label">
                    Cover:
                </p>
                <image-uploader
                    :files="coverImage"
                    type="cover"
                    @detele-image="onDeteleImage('cover')"
                    @image-uploaded="onImageUploaded"
                />
            </div>
            <div class="col-6">
                <p class="image_label">
                    High resolution cover:
                </p>
                <image-uploader
                    :files="highResolutionImage"
                    type="high_resolution_cover"
                    @detele-image="onDeteleImage('high_resolution_cover')"
                    @image-uploaded="onImageUploaded"
                />
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <button
                    class="btn btn-success btn-lg btn-block"
                    type="button"
                    @click="updateOrCreate"
                >
                    {{ gameId ? "Update" : "Create" }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import _isEmpty from "lodash/isEmpty"
import Switches from "vue-switches";
import ImageUploader from "@c/image-uploader";

export default {
    name: "GamesForm",
    components: {
        Switches,
        ImageUploader
    },
    data() {
        return {
            game: {
                name: "",
                store_name: "",
                files: []
            }
        }
    },
    computed: {
        resource() {
            return this.$route.params.resource;
        },
        gameId() {
            return this.$route.params.id;
        },
        coverImage() {
            const coverImage = this.game.files.find(({ field_name: fieldName }) => fieldName == "cover");
            return coverImage ? coverImage : {};
        },
        highResolutionImage() {
            const highResolutionImage = this.game.files.find(({ field_name: fieldName }) => fieldName == "high_resolution_cover");
            return highResolutionImage ? highResolutionImage : {};
        }
    },
    mounted() {
        if (this.gameId) {
            this.getGame();
        }
    },
    methods: {
        async getGame() {
            this.$store.commit("Application/SET_IS_LOADING", true);

            try {
                const { data: gameData } = await axios(`/${ this.resource }/${ this.gameId }?relationships=files`);
                this.game = {
                    ...this.game,
                    ...gameData,
                    is_published: Boolean(Number(gameData.is_published)),
                    vsync: Boolean(Number(gameData.vsync))
                };
            } catch (e) {
                this.$notify({
                    text: e.response.data.errors.message,
                    type: "error"
                })
            } finally {
                this.$store.commit("Application/SET_IS_LOADING", false);
            }
        },
        onImageUploaded(file) {
            const currentGameFiles = this.game.files;

            const newImage = {
                field_name: file.field_name,
                url: file.image.url,
                filesystem_id: file.image.id
            };

            const newImageFiles = [...currentGameFiles, newImage];

            this.game.files = newImageFiles;
        },
        onDeteleImage(type) {
            const deteled = this.game.files.filter(({ field_name: fieldName }) => fieldName != type);
            this.game.files = deteled;
        },
        async update(data) {
            const { is_published: isPublished, vsync, files, ...rest } = data;

            if (_isEmpty(files)) {
                this.$notify({
                    title: "Build Image",
                    text: "You cannot create or update a build without an image.",
                    type: "warn"
                });

                return;
            }

            await axios({
                url: `/games/${ this.gameId }`,
                method: "PUT",
                data: { files, is_published: String(Number(isPublished)), vsync: String(Number(vsync)), ...rest }
            });

            this.$notify({
                title: "Updated",
                text: "The Game has been updated.",
                type: "success"
            });

            this.getGame();
        },
        async create(data) {
            if (_isEmpty(this.game.files)) {
                this.$notify({
                    title: "Build Image",
                    text: "You cannot create or update a build without an image.",
                    type: "warn"
                });

                return;
            }

            await axios({
                url: `/games`,
                method: "POST",
                data: { ...data, is_published: String(Number(data.is_published)), vsync: String(Number(data.vsync)) }
            });

            this.$notify({
                title: "Created",
                text: "The Game has been created.",
                type: "success"
            });
        },
        updateOrCreate() {
            this.gameId ? this.update(this.game) : this.create(this.game);
        }
    }
}
</script>

<style lang="scss" scoped>
.game-switch {
    width: 300px;
    display: flex;
    justify-content: space-between;

    .published-switch {
        margin: -2px 8px;
    }
}

.image_label {
    display: block;
    margin-bottom: -20px;
}
</style>
