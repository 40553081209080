<template>
    <transition name="fade">
        <ValidationObserver ref="observer" class="resource-form" tag="div">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <ValidationProvider v-slot="{ errors }" rules="required">
                        <div :class="{ 'border-danger': errors[0] }" class="form-group form-group-default">
                            <label :class="{'text-danger': errors[0] }" class="form-label">
                                Name
                                <span v-if="errors[0]">(required)</span>
                            </label>
                            <input
                                v-model.trim="name"
                                name="name"
                                data-vv-validate-on="blur"
                                class="form-control"
                                type="text"
                            >
                        </div>
                    </ValidationProvider>
                </div>
                <div class="col-12 col-lg-6">
                    <ValidationProvider v-slot="{ errors }" rules="required">
                        <div :class="{ 'border-danger': errors[0] }" class="form-group form-group-default">
                            <label :class="{'text-danger': errors[0] }" class="form-label">
                                Slug
                                <span v-if="errors[0]">(required)</span>
                            </label>
                            <div class="slug-container">
                                <input
                                    v-model.trim="formData.slug"
                                    name="slug"
                                    data-vv-validate-on="blur"
                                    class="form-control"
                                    :disabled="showPublishedToggle == 0"
                                    type="text"
                                >
                                <button v-if="showPublishedToggle" class="generate-slug-btn" @click.prevent="generateSlug">
                                    Generate
                                </button>
                            </div>
                        </div>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <ValidationProvider v-slot="{ errors }" rules="required">
                        <div :class="{ 'border-danger': errors[0]}" class="form-group form-group-default">
                            <label :class="{'text-danger': errors[0] }" class="form-label">
                                Sku
                                <span v-if="errors[0]">(required)</span>
                            </label>
                            <input
                                v-model.trim="formData.sku"
                                name="sku"
                                data-vv-validate-on="blur"
                                class="form-control"
                                type="text"
                            >
                        </div>
                    </ValidationProvider>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="form-group form-group-default">
                        <label class="form-label">
                            Shopify Handle
                        </label>
                        <input
                            v-model.trim="formData.shopify_handle"
                            name="shopify-handle"
                            data-vv-validate-on="blur"
                            class="form-control"
                            type="text"
                        >
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <ValidationProvider v-slot="{ errors }" rules="required">
                        <div :class="{ 'border-danger': errors[0] }" class="form-group form-group-default">
                            <label :class="{'text-danger': errors[0] }" class="form-label">
                                Pc Part Picker Slug
                                <span v-if="errors[0]">(required)</span>
                            </label>
                            <input
                                v-model.trim="formData.slug_pcpartpicker"
                                name="slug-pcpartpicker"
                                data-vv-validate-on="blur"
                                class="form-control"
                                type="text"
                            >
                        </div>
                    </ValidationProvider>
                </div>

                <div v-if="showPublishedToggle" class="col-12 col-lg-6">
                    <label class="form-label switch-label">Published:</label>
                    <switches
                        v-model="formData.is_published"
                        class="published-switch"
                        :color="formData.is_published ? 'green' : 'default'"
                        theme="bulma"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-lg-6">
                    <label class="form-label switch-label">UPC/GTIN:</label>
                    <input
                        v-model="upc"
                        name="upc"
                        class="form-control"
                        type="text"
                    >
                </div>
            </div>

            <div>
                <div class="row">
                    <div class="col-3">
                        <h3>Colors</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <language-locale-field />
                    </div>
                </div>
                <div class="color-picker-wrapper">
                    <color-picker v-model="colorsPickers" />
                    <div class="color-picker-swatches">
                        <div class="color-generator-container">
                            <div class="color-preview-container">
                                <span :style="{ backgroundColor: colorsPickers.hex }" class="color-generator-preview" />
                            </div>
                            <input
                                v-model="currentColorName"
                                class="form-control"
                                type="text"
                                placeholder="Insert a color name"
                            >
                            <a class="add-swatch-btn swatch-btn" @click="addColor">
                                <i class="fa fa-plus" aria-hidden="true" />
                            </a>
                        </div>
                        <div class="swatches-container">
                            <div v-for="(variantColors, index) in currentColors" :key="index" class="swatch-color">
                                <div class="color-preview-container">
                                    <span class="color-generator-preview" :style="{backgroundColor: variantColors.color}" />
                                </div>
                                <input
                                    class="form-control"
                                    type="text"
                                    readonly
                                    :value="variantColors.name[currentLocale]"
                                >
                                <a class="remove-swatch-btn swatch-btn" @click="removeColor(index)">
                                    <i class="fa fa-close" aria-hidden="true" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <h3> Images </h3>
            </div>
            <div class="row">
                <image-preview-list
                    :images="formData.files"
                    @image-uploaded="onImageUploaded"
                    @image-delete="onImageDelete"
                />
            </div>

            <form-actions
                :is-loading="false"
                @save="submitProductVariant"
                @cancel="$emit('cancel')"
            >
                <template v-slot:submit-label>
                    Save Variant
                </template>
            </form-actions>
        </ValidationObserver>
    </transition>
</template>

<script>

import { ValidationObserver, ValidationProvider } from "vee-validate";
import Switches from "vue-switches";
import FormActions from "@c/form-actions.vue";
import { mapState, mapGetters } from "vuex";
import Vue from "vue";
import formsMixin from "@m/formsMixin.js";
const slugify = require("slugify");
import ImagePreviewList from "@c/images/";
import { Chrome } from "vue-color";
import LanguageLocaleField from "@c/language-locale-field";
import _isEmpty from "lodash/isEmpty";

export default {
    name: "StoreVariantForm",
    components: {
        Switches,
        FormActions,
        ImagePreviewList,
        LanguageLocaleField,
        "color-picker": Chrome,
        ValidationObserver,
        ValidationProvider
    },
    mixins: [formsMixin],
    props: {
        variant: {
            type: [Object, Array],
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            formData: {
                name: "{}",
                is_published: false,
                slug: "",
                sku: "",
                shopify_handle: "",
                slug_pcpartpicker: "",
                files: [],
                upc: "",
                products_id: this.$route.params.id
            },
            currentColorName: "",
            colorsPickers: {
                hex: "#000000"
            },
            currentColors: []
        }
    },
    computed: {
        ...mapState({
            currentLocale: state => state.Locale.currentLocale.locale
        }),
        ...mapGetters({
            userRoles: "User/userRoles"
        }),
        isEditing() {
            return Boolean(this.variant.id);
        },
        showPublishedToggle() {
            return this.userRoles.includes("Admins");
        },
        submitUrl() {
            const { id: variantId } = this.formData;
            const url = this.isEditing ? `/products-variants/${variantId}` : "/products-variants";
            return url;
        },
        submitMethod() {
            return this.isEditing ? "PUT" : "POST";
        },
        name: {
            get() {
                return this.formData.name[this.currentLocale];
            },
            set(value) {
                Vue.set(this.formData.name, this.currentLocale, value);
            }
        },
        upc: {
            get() {
                return this.formData.upc;
            },
            set(value) {
                this.formData.upc = value;
            }
        }
    },
    mounted() {
        this.setCurrentColors();
    },
    created() {
        if (this.isEditing) {
            this.formData = this.parseVariantFormFields(this.variant);
        } else {
            this.formData = this.parseVariantFormFields(this.formData);
        }
    },
    methods: {
        setCurrentColors() {
            if (!_isEmpty(this.formData.colors)) {
                const parsedColors = JSON.parse(this.formData.colors);
                this.currentColors = parsedColors;
                return;
            }

            this.currentColors = [];
        },
        addColor() {
            if (!this.currentColorName) {
                return;
            }

            const newColor = {
                color: this.colorsPickers.hex,
                name: {
                    ["en_US"]: this.currentColorName
                }
            }

            this.currentColors = [...this.currentColors, newColor]
        },
        removeColor(colorIndex) {
            const filteredColors = this.currentColors.filter((_, index) => index !== colorIndex);
            this.currentColors = filteredColors
        },
        async submitProductVariant() {
            const isValid = await this.$refs.observer.validate();

            if (!isValid) {
                return;
            }

            this.$_submitResource(this.prepareVariantFormFields, this.onSubmit)
        },
        beforeSubmit(data) {
            return this.prepareVariantFormFields(data);
        },
        onSubmit() {
            this.$notify({
                text: `Form was saved successfully.`,
                type: "success"
            });
            this.$store.dispatch("ProductForm/updateVariantProducts");
        },
        prepareBooleanFields(data) {
            return {
                ...data,
                is_published: Number(data.is_published),
                is_oversellable: Number(data.is_oversellable),
                is_default: Number(data.is_default)
            };
        },
        parseVariantFormFields(data) {
            const preparedFields = this.prepareBooleanFields(data);
            return {
                ...preparedFields,
                name: JSON.parse(data.name) || {}
            };
        },
        prepareVariantFormFields(data) {
            const preparedFields = this.prepareBooleanFields(data);
            return {
                ...preparedFields,
                name: JSON.stringify(data.name),
                colors: JSON.stringify(this.currentColors)
            };
        },
        generateSlug() {
            const name = this.formData.name[this.currentLocale];
            if (!name) {
                return;
            }
            this.formData.slug = slugify(name, { lower: true });
        },
        onImageUploaded(file) {
            const newImage = {
                field_name: file.field_name,
                url: file.image.url,
                filesystem_id: file.image.id
            }

            this.formData.files.push(newImage);
        },
        onImageDelete(id) {
            this.formData.files = this.formData.files.filter((file => file.id != id));
        }
    }
}
</script>

<style lang="scss" scoped>
.color-generator-preview {
    display: inline-block;
    width: 100%;
    height: 18px;
    border: 1px solid #cfd8dc;
}
.color-preview-container {
    padding:5px;
    width: 35px;
    display: flex;
    align-items: center;
}
.color-generator-container {
    display: flex;
    padding: 5px;
}
.swatch-btn {
    background-color: #51007a;
    color: #fff !important;
}
.swatch-btn {
    position: relative;
    margin-bottom: 0px;
    display: inline-block;
    padding: 10px;
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 50px;
}
.remove-swatch-btn {
    background-color: #fff;
    color: #ed5565 !important;
}
.color-picker-wrapper {
    position: relative;
    display: flex;
    height: 250px;
}
.color-picker-swatches {
    overflow-y: auto;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0,0,0,.12), 0 2px 5px rgba(0,0,0,.16);
    margin-left: 5px;
}
.swatches-container {
    padding: 10px 6px;
    overflow: hidden;
    border-top: 4px double #a9a9a9;
}
.swatch-color {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
/* READONLY STYLES */
.readonly .vc-chrome,
.readonly .color-generator-container,
.readonly .swatch-btn {
    display: none;
}
.readonly .swatches-container {
    border-top: 0px;
    padding: 0px;
}
.readonly .color-picker-wrapper {
    height: auto;
}
.readonly .color-picker-swatches[data-v-14616980] {
    background-color: transparent;
    box-shadow: none;
}
.readonly .form-control {
    background: transparent !important;
    border: 0px !important;
}
.readonly .swatch-color {
    margin-bottom: 0px;
}

.resource-form {
    .published-switch {
        margin-bottom: 0;
        margin-left: 70px;
    }

    .switch-label {
        width: 92px;
    }

    .slug-container {
        display: flex;
    }

    .generate-slug-btn {
        height: 18px;
        font-size: 12px;
        padding: 1px;
        background: #f7f7f7;
        border: 0.5px solid rgba(184, 184, 184, 0.849);
        cursor: pointer;
    }

    .generate-slug-btn:focus {
        outline:0;
    }
}
</style>

<style lang="scss" src="@/assets/scss/resource.scss" />
