<template>
    <div>
        <template v-for="({ categoryName, product, builderCategoryId, variant }, index) in partsDetails">
            <card
                :key="`product-${ index }`"
                :category-name="categoryName"
                :builder-category-id="builderCategoryId"
                :product="product"
                :variant="variant"
                :index="index"
                :is-loading="isLoading"
                @detele-part="detelePart"
            />
        </template>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import Card from "./card";

export default {
    name: "PartsDetails",
    components: {
        Card
    },
    props: {
        buildPartsDetails: {
            type: Array,
            default() {
                return [];
            }
        },
        parts: {
            type: Map,
            default() {
                return new Map();
            }
        },
        partsVariants: {
            type: Array,
            default() {
                return [];
            }
        },
        partsCategories: {
            type: Array,
            default() {
                return [];
            }
        },
        isLoading: {
            type: Boolean, 
            default: false
        }
    },
    computed: {
        partsDetails() {
            if (!this.isLoading) {
                let parts = [];

                for (const part of this.buildPartsDetails) {
                    const { builder_category_id: builderCategoryId } = part;
                    const { name: categoryName } = this.partsCategories.find(({ id }) => id == builderCategoryId);

                    parts = [
                        ...parts, 
                        { 
                            categoryName, 
                            builderCategoryId, 
                            product: part
                        }
                    ];
                }

                return parts;
            }

            return [{ categoryName:"", product: {} }];
        }
    },
    methods: {
        ...mapActions({
            updateBuildParts: "BuildForm/updateBuildParts"
        }),
        detelePart(index) {
            const deleted = this.buildPartsDetails.filter((_, i) => (i != index));
            this.updateBuildParts(deleted);
        }
    }
}
</script>
