const ID_SETTING_KEY = "region_id";

const state = {
    currentRegionId: null
};

const mutations = {
    SET_CURRENT_REGION_ID(state, regionId) {
        state.currentRegionId = regionId;
    }
};

const actions = {
    setIdByCompany({ commit }, companySettings = []) {
        const regionSetting = companySettings.find((setting) => setting.name === ID_SETTING_KEY) || {};
        const { value: regionId = null } = regionSetting;

        commit("SET_CURRENT_REGION_ID", regionId);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
