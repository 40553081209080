export default {
    data() {
        return {
            shouldToggleForm: false
        }
    },
    methods: {
        $_toggleGeneralFormFold() {
            this.shouldToggleForm = !this.shouldToggleForm;
        }
    }
}
