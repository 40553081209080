import { mapState } from "vuex";
import { composeResources } from "@/utils/helpers";

export default {
    computed: {
        ...mapState({
            resources: state => composeResources(state.Application.resources)
        }),
        isEditing() {
            return Boolean(this.formData.id || this.$route.params.id);
        }
    },
    methods: {
        $_fetchResource(onSuccess, onError) {
            this.$store.commit("Application/SET_IS_LOADING", true);

            axios.get(this.fetchUrl)
                .then(({ data }) => {
                    if (onSuccess) {
                        onSuccess(data);
                        return;
                    }

                    this.formData = data;
                })
                .catch((error) => {
                    if (onError) {
                        onError(error);
                        return;
                    }

                    this.$notify({
                        text: error.response.data.errors.message,
                        type: "error"
                    });
                }).finally(() => {
                    this.$store.commit("Application/SET_IS_LOADING", false);
                });
        },
        async $_submitResource(beforeSubmit, onSuccess, onError) {
            const areFieldsValid = await this.validateFields();
            if (!areFieldsValid) {
                return;
            }

            this.$store.commit("Application/SET_IS_LOADING", true);
            axios({
                url: this.submitUrl,
                method: this.submitMethod,
                data: beforeSubmit ? beforeSubmit(this.formData) : this.formData
            }).then(({ data }) => {
                if (onSuccess) {
                    onSuccess(data);
                    return;
                }

                this.$notify({
                    text: `Form was saved successfully.`,
                    type: "success"
                });
            }).catch((error) => {
                if (onError) {
                    onError(error);
                    return;
                }

                this.$notify({
                    text: error.response.data.errors.message,
                    type: "error"
                });
            }).finally(() => {
                this.$store.commit("Application/SET_IS_LOADING", false);
            })
        },
        async validateFields() {
            // This method validates fields from the parent trough all the children, since the resources form fields
            // are not all on the same level the $validator.validateAll() method will miss fields in children components.
            // so we have to run validateAll in the parent and in children components.

            this.$validator.errors.clear();
            const validations = [];

            const actualComponentValidations = this.$validator.validateAll()
            validations.push(actualComponentValidations)

            this.$children.forEach((vm) => {
                validations.push(vm.$validator.validateAll());
            });

            return Promise.all(validations).then((validationsResults) => {
                return !validationsResults.includes(false);
            });
        }
    }
}
