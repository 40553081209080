<template>
    <resource-card>
        <template v-slot:image>
            <img v-if="image" :src="image.url">
            <div v-else class="placeholder_image" />
        </template>
        <template v-if="!image" v-slot:primary-button>
            <input
                id="file"
                class="input-file"
                type="file"
                name="file"
                @change="uploadImage"
            >
            <label class="input-file-label" for="file">
                New
            </label>
        </template>
        <template v-else v-slot:secondary-button>
            <delete-button :delete-title="`'${image.name}'`" @delete-confirmed="$emit('detele-image')" />
        </template>
    </resource-card>
</template>

<script>
import ResourceCard from "@c/resource-card";
import DeleteButton from "@c/delete-button";

export default {
    name: "ImageUploader",
    components: {
        ResourceCard,
        DeleteButton
    },
    props: {
        files: {
            type: [Array, Object],
            default() {
                return [];
            }
        },
        type: {
            type: String,
            required: true
        }
    },
    computed: {
        image() {
            if (this.files.url) {
                return this.files;
            }

            return false;
        }
    },
    methods: {
        async uploadImage(event) {
            const image = event.target.files[0];

            this.$store.commit("Application/SET_IS_LOADING", true);

            try {
                const formData = new FormData();
                formData.append(this.imageType, image);
                formData.append("name", image.name);

                await axios({
                    method: "POST",
                    url: "/filesystem",
                    data: formData
                }).then(({ data: imageFile }) => {
                    this.imageFile = imageFile[0];
                    this.$emit("image-uploaded", { image: this.imageFile, field_name: `${ this.type }` });
                })
            } catch (e) {
                this.$notify({
                    text: e.response.data.errors.message,
                    type: "error"
                });
            } finally {
                this.$store.commit("Application/SET_IS_LOADING", false);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.placeholder_image {
    background-color: rgb(201, 201, 201);
    width: 150px;
    height: 150px;
    border-radius: 3px;
}

.input-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.input-file-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-right: 10px;
    height: 40px;
    width: 70px;
    background: transparent;
    border: 1px solid rgb(110, 110, 110);
    color: gray;
    border-radius: 3px;
    font-weight: 500;
    cursor: pointer;


    &:hover {
        background-color: rgb(201, 201, 201);
        border: 0;
        color: white;
    }
}

img {
    width: 150px;
    height: 150px;
    object-fit: contain;
    object-position: center;
}
</style>
