<template>
    <div class="create-resource">
        <div class="top-section">
            <h4 class="section-title p-l-10">
                Create {{ resourceName }}
            </h4>
            <products-tab
                v-if="!shouldShow"
                @tab-changed="(tabName) => currentForm = tabName"
            />
        </div>
        <div class="card">
            <div v-if="shouldShow" class="card-block">
                <component :is="customForm" />
            </div>
            <div v-else class="card-block">
                <component :is="currentForm" />
            </div>
        </div>
        <div>
            <button 
                title="Archive"
                type="button"
                class="btn btn-primary"
                @click="confirmArchive()"
            >
                Archive Product 
            </button>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { composeResources } from "@/utils/helpers";
import ProductsTab from "./product-tabs/";
import GeneralForm from "./general-form/";
import StoreForm from "./store-form/";
import BldForm from "./bld-form/";

// Custom forms
import BuildForm from "./build-form";
import GameForm from "./game-form";
import RulesForm from "./rules-form";
import CategoriesForm from "./categories-form";
import AttributesFrom from "./attributes-form";
import ResourceDeleteModal from "@c/modals/resource-delete-modal";
import { specialResources } from "@/config/constants";

export default {
    name: "CreateResource",
    components: {
        ProductsTab,
        GeneralForm,
        StoreForm,
        BldForm,
        "rules": RulesForm,
        "games": GameForm,
        "recommended-builds": BuildForm,
        "categories": CategoriesForm,
        "attributes": AttributesFrom
    },
    data() {
        return {
            currentResource: {},
            currentForm: "general-form",
            customForm: ""
        };
    },
    computed: {
        ...mapState({
            resources: state => composeResources(state.Application.resources),
            formData: state => state.ProductForm.formData
        }),
        shouldShow() {
            const buildResources = ["recommended-builds", "games", "rules", "categories", "attributes"];
            return buildResources.includes(this.$route.params.resource);
        },
        resourceName() {
            const { name } = this.currentResource;
            return name;
        }
    },
    created() {
        this.setResource(this.$route.params.resource);

        if (this.shouldShow) {
            this.customForm = this.$route.params.resource;
        }
    },
    methods: {
        setResource(resourceSlug) {
            const resourceIndex = this.resources.findIndex(resource => {
                return resource.slug == resourceSlug;
            });
            this.currentResource = this.resources[resourceIndex];
        },
        confirmArchive() {
            this.$modal.show(ResourceDeleteModal, {
                buttons: [{
                    title: "Cancel",
                    handler: () => {
                        this.$modal.hide("delete-resource");
                    }
                }, {
                    title: "Archive",
                    class: "btn-secondary",
                    handler: () => {
                        this.archiveResource();
                    }
                }],
                deleteEntity: "this"
            }, {
                adaptive: true,
                clickToClose: false,
                height: "auto",
                name: "delete-resource",
                width: 500
            });
        },
        async archiveResource() {
            const path = specialResources.includes(this.$route.params.resource) ? this.$route.params.resource : "products";

            try {
                await axios({
                    url: `/${path}/${this.formData.id}`,
                    method: "PUT",
                    body: JSON.stringify({
                        is_published: 0
                    })
                })

                this.$notify({
                    text: `${this.formData.name} was archived successfully.`,
                    type: "success"
                });

                this.$router.push({
                    name: "dashboard"
                });

            } catch (error) {

                this.$notify({
                    text: error.response.data.errors.message,
                    type: "error"
                });
            } finally {
                this.$modal.hide("delete-resource");
            }
        }
    }
}
</script>

<style lang="scss">
.create-resource {
    .card {
        margin-top: 30px;
    }

    .top-section {
        ul {
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }
}
</style>
