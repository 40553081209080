<template>
    <ul class="product-tabs">
        <li v-for="(productTab) in productTabs" :key="productTab.id">
            <button
                class="btn"
                :class="{'selected-tab': selectedTab.id == productTab.id, 'disabled-tab': shouldDisableTab(productTab) }"
                @click="onTabSelected(productTab)"
            >
                {{ productTab.label }}
            </button>
        </li>
    </ul>
</template>

<script>
export default {
    name: "ProductTabs",
    data() {
        return {
            productTabs: [
                {
                    id: 1,
                    name: "general-form",
                    label: "General"
                },
                {
                    id: 2,
                    name: "store-form",
                    label: "Store"
                },
                {
                    id: 3,
                    name: "bld-form",
                    label: "BLD"
                }
            ],
            selectedTab: { id: 1 },
            storeTabs: ["store-form", "bld-form"]
        }
    },
    methods: {
        shouldDisableTab(tab) {
            const isCreatingResource = this.$route.name == "create-resource";
            return isCreatingResource && this.storeTabs.includes(tab.name);
        },
        onTabSelected(tab) {
            const isTabDisabled = this.shouldDisableTab(tab);
            if (isTabDisabled) {
                return;
            }

            this.selectedTab = tab;
            this.$emit("tab-changed", tab.name);
        }
    }
}
</script>

<style lang="scss">
.product-tabs {
    li {
        display: inline;
        list-style-type: none;

        margin-right: 20px;

        button {
            width: 120px;
            background-color: transparent;
            color: gray;
            font-weight: bold;
            text-transform: capitalize;
        }
    }

    .btn:focus,.btn:active {
        outline: none !important;
        box-shadow: none;
    }

    .selected-tab {
        background-color: black;
        color: white;
        width: 120px;
    }

    .disabled-tab {
        cursor: not-allowed !important;
        color: #c1c1c1;
    }
}
</style>
