const getDefaultStates = () => {
    return {
        formData: {
            id: "",
            slug: "",
            categories_id: "",
            base_category_id: "",
            page_title: {},
            description: {},
            short_description: {},
            is_published: false,
            rating: 0,
            position: 0,
            regions_products: [{}],
            options_dropdown: null,
            name: "",
            files: []
        },
        media_types: [],
        product_variants: []
    }
};

const state = getDefaultStates();

const mutations = {
    SET_FORM_DATA(state, data) {
        state.formData = data;
    },
    SET_SLUG(state, slug) {
        state.formData = {
            ...state.formData,
            slug
        };
    },
    SET_CATEGORIES_ID(state, categoryId) {
        state.formData = {
            ...state.formData,
            categories_id: categoryId
        };
    },
    SET_BASE_CATEGORY_ID(state, baseCategoryId) {
        state.formData = {
            ...state.formData,
            base_category_id: baseCategoryId
        };
    },
    SET_PAGE_TITLE(state, { locale, pageTitle }) {
        state.formData = {
            ...state.formData,
            page_title: {
                ...state.formData.page_title,
                [locale]: pageTitle
            }
        }
    },
    SET_DESCRIPTION(state, { locale, description }) {
        state.formData = {
            ...state.formData,
            description: {
                ...state.formData.description,
                [locale]: description
            }
        }
    },
    SET_SHORT_DESCRIPTION(state, { locale, shortDescription }) {
        state.formData = {
            ...state.formData,
            short_description: {
                ...state.formData.short_description,
                [locale]: shortDescription
            }
        }
    },
    SET_IS_PUBLISHED(state, isPublished) {
        state.formData = {
            ...state.formData,
            is_published: isPublished
        };
    },
    SET_RATING(state, rating) {
        state.formData = {
            ...state.formData,
            rating
        };
    },
    SET_POSITION(state, position) {
        state.formData = {
            ...state.formData,
            position
        };
    },
    SET_REGION_PRODUCTS(state, regionProducts) {
        state.regions_products = regionProducts;
    },
    SET_OPTIONS(state, option) {
        state.formData = {
            ...state.formData,
            options_dropdown: option
        };
    },
    SET_NAME(state, name) {
        state.formData = {
            ...state.formData,
            name
        };
    },
    SET_PRODUCT_VARIANTS(state, productVariants) {
        state.product_variants = productVariants;
    },
    SET_FILES(state, files) {
        state.formData = {
            ...state.formData,
            files
        };
    },
    SET_MEDIA_TYPES(state, mediaTypes) {
        state.media_types = mediaTypes;
    },
    CLEAN(state) {
        Object.assign(state, getDefaultStates());
    }
};

const actions = {
    setDescription({ commit }, { locale, description }) {
        commit("SET_DESCRIPTION", { locale, description })
    },
    setPageTitle({ commit }, { locale, pageTitle }) {
        commit("SET_PAGE_TITLE", { locale, pageTitle })
    },
    updateVariantProducts({ commit, state }) {
        axios.get(`/products/${state.formData.id}/variants?relationships=files`)
            .then(({ data: variants }) => {
                commit("SET_PRODUCT_VARIANTS", variants);
            });
    },
    updateData({ commit, dispatch }, { resourceEndpoint, id }) {
        axios.get(`/${resourceEndpoint}/${id}?relationships=files`)
            .then(({ data: productData }) => {
                commit("SET_FORM_DATA", productData);
                dispatch("parseData");
            });
    },
    addMediaTypes({ commit }) {
        axios.get("/media-types")
            .then(({ data: mediaTypes }) => {
                commit("SET_MEDIA_TYPES", mediaTypes);
            });
    },
    parseData({ commit, state }) {
        const description = JSON.parse(state.formData.description) || {};
        const pageTitle = JSON.parse(state.formData.page_title) || {};
        const shortDescription = JSON.parse(state.formData.short_description) || {};

        const parsedData = {
            ...state.formData,
            description,
            page_title: pageTitle,
            short_description: shortDescription
        }
        commit("SET_FORM_DATA", parsedData);
    },
    clean({ commit }) {
        commit("CLEAN");
    }
};

const getters = {
    variantProductLength(state) {
        return state.formData.product_variants.length;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
