<template>
    <section class="common-fields">
        <div class="row">
            <div class="col-3">
                <language-locale-field />
            </div>
        </div>
        <ValidationObserver ref="observer" tag="form">
            <div class="row">
                <div class="col-12 col-lg-4">
                    <ValidationProvider v-slot="{ errors }" rules="required">
                        <div :class="{ 'border-danger': errors[0] }" class="form-group form-group-default">
                            <label :class="{'text-danger': errors[0] }">
                                Name
                                <span v-if="errors[0]">(required)</span>
                            </label>
                            <input
                                v-model="name"
                                name="name"
                                data-vv-validate-on="blur"
                                class="form-control"
                                type="text"
                                autofocus
                            >
                        </div>
                    </ValidationProvider>
                </div>
                <div class="col-12 col-lg-4">
                    <ValidationProvider v-slot="{ errors }" rules="required">
                        <div :class="{ 'border-danger': errors[0] }" class="form-group form-group-default">
                            <label :class="{'text-danger': errors[0] }" class="form-label">
                                Page Title
                                <span v-if="errors[0]">(required)</span>
                            </label>
                            <input
                                v-model.trim="pageTitle"
                                name="page_title"
                                data-vv-validate-on="blur"
                                class="form-control"
                                type="text"
                            >
                        </div>
                    </ValidationProvider>
                </div>
                <div class="col-12 col-lg-4">
                    <ValidationProvider v-slot="{ errors }" rules="required">
                        <div :class="{ 'border-danger': errors[0] }" class="form-group form-group-default">
                            <label :class="{'text-danger': errors[0] }" class="form-label">
                                Slug
                                <span v-if="errors[0]">(required)</span>
                            </label>
                            <div class="slug-container">
                                <input
                                    v-model.trim="slug"
                                    name="slug"
                                    data-vv-validate-on="blur"
                                    class="form-control "
                                    type="text"
                                    :disabled="showPublishedToggle == 0"
                                >
                                <button v-if="showPublishedToggle" class="generate-slug-btn" @click.prevent="generateSlug">
                                    Generate
                                </button>
                            </div>
                        </div>
                    </validationprovider>
                </div>
            </div>


            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <label :class="{'text-danger': errors[0] }">
                                Short Description
                                <span v-if="errors[0]">(required)</span>
                            </label>
                            <textarea
                                v-model.trim="shortDescription"
                                name="short_description"
                                class="form-control"
                                :class="{ 'border-danger': errors[0] }"
                                rows="10"
                            />
                        </ValidationProvider>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <label :class="{'text-danger': errors[0] }">
                                Description
                                <span v-if="errors[0]">(required)</span>
                            </label>
                            <textarea
                                v-model.trim="pageDescription"
                                name="description"
                                class="form-control"
                                :class="{ 'border-danger': errors[0] }"
                                rows="15"
                            />
                        </ValidationProvider>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="form-group form-group-default">
                        <ValidationProvider rules="required">
                            <label class="form-label"> Position </label>
                            <input
                                v-model="position"
                                v-validate="'required'"
                                name="position"
                                class="form-control"
                                type="number"
                                min="0"
                            >
                        </ValidationProvider>
                    </div>
                </div>
                <div v-if="showPublishedToggle" class="col-12 col-lg-6">
                    <label class="form-label"> Published: </label>
                    <switches
                        v-model="isPublished"
                        name="published"
                        class="published-switch"
                        :color="isPublished ? 'green' : 'default'"
                        theme="bulma"
                    />
                </div>
            </div>
        </ValidationObserver>
    </section>
</template>

<script>

import { ValidationObserver, ValidationProvider } from "vee-validate";
import LanguageLocaleField from "@c/language-locale-field";
const slugify = require("slugify");
import Switches from "vue-switches";
import { mapState, mapGetters } from "vuex";
import { composeResources } from "@/utils/helpers";
import { isJson } from "@/utils/helpers";

export default {
    name: "CommonFields",
    components: {
        LanguageLocaleField,
        Switches,
        ValidationObserver,
        ValidationProvider
    },
    computed: {
        ...mapState({
            currentLocale: state => state.Locale.currentLocale.locale,
            formData: state => state.ProductForm.formData,
            resources: state => composeResources(state.Application.resources)
        }),
        ...mapGetters({
            userRoles: "User/userRoles"
        }),
        resource() {
            return this.resources.find(resource => resource.slug == this.$route.params.resource);
        },
        showPublishedToggle() {
            return this.userRoles.includes("Admins");
        },
        resourceEndpoint() {
            return this.resource.slug.split("-")[0];
        },
        name: {
            get() {
                return isJson(this.formData.name) ?
                    this.checkCurrentLocale(this.formData.name) : this.formData.name
            },
            set(value) {
                const newMessage = {
                    [this.currentLocale]: value
                }

                const newName = JSON.stringify(Object.assign({}, isJson(this.formData.name) ?
                    JSON.parse(this.formData.name) : "", newMessage))

                this.$store.commit("ProductForm/SET_NAME", newName)
            }
        },
        rating: {
            get() {
                return this.formData.rating;
            },
            set(value) {
                this.$store.commit("ProductForm/SET_RATING", value)
            }
        },
        position: {
            get() {
                return this.formData.position;
            },
            set(value) {
                this.$store.commit("ProductForm/SET_POSITION", value)
            }
        },
        options: {
            get() {
                return this.formData.options_dropdown;
            },
            set(value) {
                this.$store.commit("ProductForm/SET_OPTIONS", value)
            }
        },
        pageTitle: {
            get() {
                return this.formData.page_title[this.currentLocale];
            },
            set(value) {
                this.$store.dispatch("ProductForm/setPageTitle", { locale: this.currentLocale, pageTitle: value })
            },
            deep: true
        },
        pageDescription: {
            get() {
                return this.formData.description[this.currentLocale];
            },
            set(value) {
                this.$store.commit("ProductForm/SET_DESCRIPTION", { locale: this.currentLocale, description: value })
            }
        },
        shortDescription: {
            get() {
                return this.formData.short_description[this.currentLocale];
            },
            set(value) {
                this.$store.commit("ProductForm/SET_SHORT_DESCRIPTION", { locale: this.currentLocale, shortDescription: value })
            }
        },
        slug: {
            get() {
                return this.formData.slug;
            },
            set(value) {
                this.$store.commit("ProductForm/SET_SLUG", slugify(value))
            }
        },
        isPublished: {
            get() {
                const published = Number(this.formData.is_published);
                return Boolean(published);
            },
            set(value) {
                this.$store.commit("ProductForm/SET_IS_PUBLISHED", Number(value));
            }
        }
    },
    destroyed() {
        this.$store.dispatch("ProductForm/updateData", {
            resourceEndpoint: this.resourceEndpoint,
            id: this.$route.params.id
        });
    },
    methods: {
        slugify(text, ampersand = "and") {
            const a = "àáäâèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧ"
            const b = "aaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzh"
            const p = new RegExp(a.split("").join("|"), "g")
            return text.toString().toLowerCase()
                .replace(/[\s_]+/g, "-")
                .replace(p, c => b.charAt(a.indexOf(c)))
                .replace(/&/g, `-${ampersand}-`)
                .replace(/[^\w-]+/g, "")
                .replace(/--+/g, "-")
                .replace(/^-+|-+$/g, "")
        },
        isValid() {
            return this.$refs.observer.validate();
        },
        checkCurrentLocale(name) {
            return JSON.parse(name)[this.currentLocale] ?
                JSON.parse(name)[this.currentLocale] : JSON.parse(name)["en_US"];
        },
        generateSlug() {
            const pageTitle = this.slugify(this.formData.page_title[this.currentLocale]);
            if (!pageTitle) {
                return;
            }
            this.$store.commit("ProductForm/SET_SLUG", pageTitle);
        }
        
    }
}
</script>

<style lang="scss">
.common-fields {
    .published-switch {
        margin: 20px 15px 0 15px;
    }

    .slug-container {
        display: flex;
    }

    .generate-slug-btn {
        height: 18px;
        font-size: 12px;
        padding: 1px;
        background: #f7f7f7;
        border: 0.5px solid rgba(184, 184, 184, 0.849);
        cursor: pointer;
    }

    .generate-slug-btn:focus {
        outline:0;
    }

}

</style>
