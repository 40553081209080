<template>
    <transition name="fade">
        <form class="resource-form" @submit.prevent>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="form-group form-group-default">
                        <label class="form-label">Stock</label>
                        <input
                            v-model="formData.stock"
                            name="stock"
                            class="form-control"
                            type="number"
                        >
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="form-group form-group-default">
                        <label class="form-label">Stock Threshold</label>
                        <input
                            v-model="formData.stock_threshold"
                            name="stock_threshold"
                            class="form-control"
                            type="number"
                        >
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="form-group form-group-default">
                        <label class="form-label">Price</label>
                        <input
                            v-model="formData.price"
                            name="price"
                            aria-invalid="false"
                            class="form-control"
                            type="number"
                            step="any"
                            min="0"
                            @change="disableShowPercentOff"
                        >
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="form-group form-group-default">
                        <label class="form-label">Discounted Price</label>
                        <input
                            v-model="formData.discounted_price"
                            name="discounted-price"
                            aria-invalid="false"
                            class="form-control"
                            type="number"
                            step="any"
                            min="0"
                            @change="disableShowPercentOff"
                        >
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="form-group form-group-default">
                        <label class="form-label">Sku</label>
                        <input
                            v-model="formData.sku"
                            name="discounted-price"
                            aria-invalid="false"
                            class="form-control"
                            type="text"
                        >
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label class="form-label switch-label">Published:</label>
                    <switches
                        v-model="formData.is_published"
                        class="published-switch"
                        :color="formData.is_published ? 'green' : 'default'"
                        theme="bulma"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label class="form-label switch-label">Is Out Of Stock On Store:</label>
                    <switches
                        v-model="formData.is_out_of_stock_on_store"
                        class="published-switch"
                        :color="formData.is_out_of_stock_on_store ? 'green' : 'default'"
                        theme="bulma"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label class="form-label switch-label">Is Oversellable:</label>
                    <switches
                        v-model="formData.is_oversellable"
                        class="published-switch"
                        :color="formData.is_oversellable ? 'green' : 'default'"
                        theme="bulma"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label class="form-label switch-label">Is Default:</label>
                    <switches
                        v-model="formData.is_default"
                        class="published-switch"
                        :color="formData.is_default ? 'green' : 'default'"
                        theme="bulma"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label class="form-label switch-label">Is Best Seller:</label>
                    <switches
                        v-model="formData.is_best_seller"
                        class="published-switch"
                        :color="formData.is_best_seller ? 'green' : 'default'"
                        theme="bulma"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label class="form-label switch-label">Is Promo:</label>
                    <switches
                        v-model="formData.is_promo"
                        class="published-switch"
                        :color="formData.is_promo ? 'green' : 'default'"
                        theme="bulma"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label class="form-label switch-label">Is Sale:</label>
                    <switches
                        v-model="formData.is_sale"
                        class="published-switch"
                        :color="formData.is_sale ? 'green' : 'default'"
                        theme="bulma"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label class="form-label switch-label">Is On Clearance:</label>
                    <switches
                        v-model="formData.show_on_clearance"
                        class="published-switch"
                        :color="formData.show_on_clearance ? 'green' : 'default'"
                        theme="bulma"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label class="form-label switch-label">Is Limited Availability:</label>
                    <switches
                        v-model="formData.is_limited_availability"
                        class="published-switch"
                        :color="formData.is_limited_availability ? 'green' : 'default'"
                        theme="bulma"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label class="form-label switch-label">Show Amount Off:</label>
                    <switches
                        v-model="formData.show_amount_off"
                        class="published-switch"
                        :color="formData.show_amount_off ? 'green' : 'default'"
                        theme="bulma"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label class="form-label switch-label">Show % off:</label>
                    <switches
                        v-model="formData.show_percent_off"
                        class="published-switch"
                        :color="formData.show_percent_off ? 'green' : 'default'"
                        theme="bulma"
                        :disabled="disableShowPercentOff"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label class="form-label switch-label">Can Pre-order:</label>
                    <switches
                        v-model="formData.can_preorder"
                        class="published-switch"
                        :color="formData.can_preorder ? 'green' : 'default'"
                        theme="bulma"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label class="form-label switch-label">Is Coming Soon:</label>
                    <switches
                        v-model="formData.is_coming_soon"
                        class="published-switch"
                        :color="formData.is_coming_soon ? 'green' : 'default'"
                        theme="bulma"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label class="form-label switch-label">Is New Item:</label>
                    <switches
                        v-model="formData.is_new_item"
                        class="published-switch"
                        :color="formData.is_new_item ? 'green' : 'default'"
                        theme="bulma"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label class="form-label switch-label">Is Inventory Countdown:</label>
                    <switches
                        v-model="formData.show_inventory_countdown"
                        class="published-switch"
                        :color="formData.show_inventory_countdown ? 'green' : 'default'"
                        theme="bulma"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label class="form-label switch-label">Is Time Countdown:</label>
                    <switches
                        v-model="formData.show_countdown_timer"
                        class="published-switch"
                        :color="formData.show_countdown_timer ? 'green' : 'default'"
                        theme="bulma"
                    />
                </div>
                <div
                    v-show="formData.show_countdown_timer"
                    class="col-3"
                    style="display: flex; align-items: center"
                >
                    <input
                        :value="formData.countdown_ends_on"
                        :min="countdownEndsMin"
                        type="datetime-local"
                        @input="countdownEndsChange"
                    >
                </div>
            </div>

            <div class="row">
                <div class="col-3">
                    <label class="form-label switch-label">Refresh inventory daily:</label>
                    <switches
                        v-model="formData.daily_blitz_refresh "
                        class="published-switch"
                        :color="formData.daily_blitz_refresh  ? 'green' : 'default'"
                        theme="bulma"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-3">
                    <label class="form-label switch-label">Blitz Mode:</label>
                    <switches
                        v-model="formData.blitz_mode_available"
                        class="published-switch"
                        :color="formData.blitz_mode_available ? 'green' : 'default'"
                        theme="bulma"
                    />
                </div>
                <div
                    v-show="formData.blitz_mode_available"
                    class="col-3"
                    style="display: flex; align-items: center"
                >
                    <input
                        v-model="blitzModeLimit"
                        type="number"
                        name="blitzModeLimit"
                        class="form-control"
                    >
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <label class="form-label switch-label">UPC/GTIN:</label>
                    <input
                        v-model="upc"
                        name="upc"
                        class="form-control"
                        type="text"
                    >
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-lg-6">
                    <label class="form-label switch-label">LENGTH:</label>
                    <input
                        v-model="length"
                        name="length"
                        class="form-control"
                        type="number"
                    >
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <label class="form-label switch-label">WIDTH:</label>
                    <input
                        v-model="width"
                        name="width"
                        class="form-control"
                        type="number"
                    >
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <label class="form-label switch-label">HEIGHT:</label>
                    <input
                        v-model="height"
                        name="height"
                        class="form-control"
                        type="number"
                    >
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-12 col-lg-6">
                    <label class="form-label switch-label">WEIGHT:</label>
                    <input
                        v-model="weight"
                        name="weight"
                        class="form-control"
                        type="number"
                    >
                </div>
            </div>


            <form-actions
                :is-loading="false"
                @save="submitProductVariant"
                @cancel="$emit('cancel')"
            >
                <template v-slot:submit-label>
                    Save Variant
                </template>
            </form-actions>
        </form>
    </transition>
</template>

<script>
import Switches from "vue-switches";
import FormActions from "@c/form-actions.vue";
import { mapState } from "vuex";
import formsMixin from "@m/formsMixin.js";
import moment from "moment";
import "moment-timezone";

export default {
    name: "StoreVariantForm",
    components: {
        Switches,
        FormActions
    },
    mixins: [formsMixin],
    props: {
        variant: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            formData: {
                stock: 0,
                price: 0,
                discounted_price: 0,
                stock_threshold: 0,
                is_best_seller: false,
                can_preorder: false,
                is_published: false,
                is_default: false,
                is_oversellable: false,
                is_out_of_stock_on_store: false,
                is_coming_soon: false,
                show_on_clearance: false,
                is_limited_availability: false,
                show_amount_off: false,
                show_inventory_countdown: false,
                show_countdown_timer: false,
                countdown_ends_on: 0,
                is_promo: false,
                is_sale: false,
                show_percent_off: false,
                is_new_item: false,
                sku: "",
                upc: "",
                length: 0,
                width: 0,
                height: 0,
                weight: 0,
                blitz_mode_limit: 0,
                blitz_mode_available: false,
                daily_blitz_refresh: false

            },
            countdownEndsMin: null,
            submitMethod: "PUT"
        }
    },
    computed: {
        ...mapState({
            currentLocale: state => state.Locale.currentLocale.locale
        }),
        submitUrl() {
            const {
                regions_id: rId,
                products_id: pId,
                products_variants_id: vId
            } = this.formData;

            return `/regions/${rId}/products/${pId}/variants/${vId}`;
        },
        disableShowPercentOff() {
            if (this.formData.price > this.formData.discounted_price) {
                return this.formData.discounted_price == 0 ? true : false;
            }

            this.updateShowPercentOff(Number(0));

            return true;
        },
        name: {
            get() {
                return this.formData.name[this.currentLocale];
            },
            set(value) {
                Vue.set(this.formData.name, this.currentLocale, value);
            }
        },
        upc: {
            get() {
                return this.formData.upc;
            },
            set(value) {
                this.formData.upc = value;
            }
        },
        length: {
            get() {
                return this.formData.length;
            },
            set(value) {
                this.formData.length = value;
            }
        },
        width: {
            get() {
                return this.formData.width;
            },
            set(value) {
                this.formData.width = value;
            }
        },
        height: {
            get() {
                return this.formData.height;
            },
            set(value) {
                this.formData.height = value;
            }
        },
        weight: {
            get() {
                return this.formData.weight;
            },
            set(value) {
                this.formData.weight = value;
            }
        },
        blitzModeLimit: {
            get() {
                return this.formData.blitz_mode_limit;
            },
            set(value) {
                this.formData.blitz_mode_limit = value;
            }
        }
    },
    watch: {
        "formData.show_countdown_timer"(state) {
            if (state) {
                if (!this.formData.countdown_ends_on) {
                    this.formData.countdown_ends_on = this.countdownDateToUTC(moment().format("YYYY-MM-DDTHH:mm"));
                }
            }
        }
    },
    created() {
        this.formData = this.parseVariantFormFields(this.variant);
        this.countdownEndsMin = this.countdownDateToUTC(moment().format("YYYY-MM-DDTHH:mm"));

        if (this.formData.countdown_ends_on) {
            this.formData.countdown_ends_on = this.formData.countdown_ends_on.replace(" ", "T");
        }
    },
    methods: {
        getTimezonOffset() {
            const timezoneParts = moment().format("Z").split(":");
            return Number(`${timezoneParts[0]}.${timezoneParts[1] / 60}`);
        },
        countdownDateToUTC(datetimeString) {
            const datetime = datetimeString.replace("T", " ");
            const timezoneOffet = this.getTimezonOffset();
            return moment(`${datetime}`).subtract(timezoneOffet, "hours").format("YYYY-MM-DDTHH:mm");
        },
        countdownDateToLocal() {
            const timezoneOffet = this.getTimezonOffset();
            return moment(`${this.formData.countdown_ends_on}`).add(timezoneOffet, "hours").format("YYYY-MM-DD HH:mm");
        },
        countdownEndsChange(event) {
            this.formData.countdown_ends_on = event.target.value;
        },
        submitProductVariant() {
            if (this.formData.show_countdown_timer) {
                const currentDateTime = +moment().second(0).format("X");
                const formDateTime = +moment(this.countdownDateToLocal()).format("X");

                if (formDateTime < currentDateTime) {
                    return;
                }
            }

            this.$_submitResource(this.prepareVariantFormFields, this.onSubmit);
        },
        onSubmit() {
            this.$notify({
                text: `Store variant form was saved successfully.`,
                type: "success"
            });
            this.$store.dispatch("StoreForm/updateVariants");
        },
        updateShowPercentOff(value) {
            this.formData.show_percent_off = value;
        },
        prepareVariantFormFields(data) {
            const preparedFields = this.prepareBooleanFormFields(data);
            return {
                ...preparedFields,
                countdown_ends_on: data.countdown_ends_on ? data.countdown_ends_on.replace("T", " ") : data.countdown_ends_on,
                name: JSON.stringify(data.name)
            };
        },
        parseVariantFormFields(data) {
            const preparedFields = this.prepareBooleanFormFields(data);
            return {
                ...preparedFields,
                name: JSON.parse(data.name) || {}
            };
        },
        prepareBooleanFormFields(data) {
            return {
                ...data,
                is_published: Number(data.is_published),
                is_oversellable: Number(data.is_oversellable),
                is_default: Number(data.is_default),
                is_best_seller: Number(data.is_best_seller),
                is_out_of_stock_on_store: Number(data.is_out_of_stock_on_store),
                can_preorder: Number(data.can_preorder),
                is_coming_soon: Number(data.is_coming_soon),
                show_amount_off: Number(data.show_amount_off),
                show_on_clearance: Number(data.show_on_clearance),
                is_limited_availability: Number(data.is_limited_availability),
                show_countdown_timer: Number(data.show_countdown_timer),
                show_inventory_countdown: Number(data.show_inventory_countdown),
                is_promo: Number(data.is_promo),
                is_sale: Number(data.is_sale),
                show_percent_off: Number(data.show_percent_off),
                is_new_item: Number(data.is_new_item),
                blitz_mode_available: Number(data.blitz_mode_available),
                daily_blitz_refresh : Number(data.daily_blitz_refresh)
            };
        }
    }
}
</script>

<style lang="scss" scoped>
.resource-form {
    .published-switch {
        margin-bottom: 0;
        margin-left: 70px;
    }

    .switch-label {
        width: 92px;
    }
}
</style>

<style lang="scss" src="@/assets/scss/resource.scss" />
