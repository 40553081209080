import Vue from "vue";
import _clone from "lodash/clone";
import store from "@/store";

const onError = (error) => {
    const { data: { errors } } = error.response;
    Vue.notify({
        title: errors.type,
        text: errors.message,
        type: "error"
    });
};

const getDefaultStates = () => {
    return {
        buildFormData: {
            name: "",
            description: "",
            cpu: "",
            files: [],
            is_published: false,
            is_from_product: false,
            price: 0,
            details: [],
            slug: "",
            builds_types_id : 1
        },
        isLoading: false,
        parts: new Map(),
        partsVariants: [],
        partsCategories: [],
        selectOptions: [
            { value: 1, label: "Recommended Build" },
            { value: 7, label: "Locked Build" }
        ]
    }
};

const state = getDefaultStates();

const getters = {
    getIsLoading(state) {
        return state.isLoading;
    },
    getbuildFormData(state) {
        return state.buildFormData;
    },
    getParts(state) {
        return state.parts;
    },
    getPartsVariants(state) {
        return state.partsVariants;
    },
    getPartsCategories(state) {
        return state.partsCategories;
    },
    getSelectOptions(state) {
        return state.selectOptions;
    }
};

const mutations = {
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading;
    },
    SET_BUILD_DATA(state, buildData) {
        state.buildFormData = buildData;
    },
    SET_SLUG(state, slug) {
        state.buildFormData = {
            ...state.buildFormData,
            slug
        };
    },
    SET_BUILD_TYPE(state, value) {
        state.buildFormData = {
            ...state.buildFormData,
            builds_types_id: value
        };
    },
    SET_OPTIONS(state, option) {
        state.buildFormData = {
            ...state.buildFormData,
            builds_types_id: option
        };
    },
    SET_PARTS(state, parts) {
        state.parts = parts;
    },
    SET_PARTS_VARIANTS(state, partsVariants) {
        state.partsVariants = partsVariants;
    },
    SET_PARTS_CATEGORIES(state, partsCategories) {
        state.partsCategories = partsCategories;
    },
    UPDATE_BUILD_PARTS(state, newPartsDetails) {
        state.buildFormData = {
            ...state.buildFormData,
            details: newPartsDetails
        };
    },
    UPDATE_BUILD_FILES(state, newFiles) {
        state.buildFormData = {
            ...state.buildFormData,
            files: newFiles
        };
    },
    CHANGE_PART_DATA(state, { index, property, value }) {
        const cloneDetails = _clone(state.buildFormData.details);
        cloneDetails[index][property] = Number(value);

        state.buildFormData = {
            ...state.buildFormData,
            details: cloneDetails
        }
    },
    CHANGE_BUILD_DATA(state, { property, value }) {
        state.buildFormData = {
            ...state.buildFormData,
            [property]: Number(value)
        };
    },
    CHANGE_BUILD_DATA_LOCALE(state, { property, value, locale }) {
        state.buildFormData = {
            ...state.buildFormData,
            [property]: {
                ...state.buildFormData[property],
                [locale]: value
            }
        };
    },
    CLEAR_BUILD_FORM_DATA(state, buildData) {
        state.buildFormData = buildData;
    },
    CLEAN(state) {
        Object.assign(state, getDefaultStates());
    }
};

const actions = {
    setIsLoading({ commit }, isLoading) {
        commit("SET_IS_LOADING", isLoading);
    },
    setBuilData({ commit }, buildFormData) {
        commit("SET_BUILD_DATA", buildFormData);
    },
    setPartsCategories({ commit }, partsCategories) {
        commit("SET_PARTS_CATEGORIES", partsCategories);
    },
    setParts({ commit }, parts) {
        commit("SET_PARTS", parts);
    },
    setPartsVariants({ commit }, partsVariants) {
        commit("SET_PARTS_VARIANTS", partsVariants);
    },
    updateBuildParts({ commit }, newPartsDetails) {
        commit("UPDATE_BUILD_PARTS", newPartsDetails)
    },
    updateBuildFiles({ commit }, newFiles) {
        commit("UPDATE_BUILD_FILES", newFiles)
    },
    changePartData({ commit }, data) {
        commit("CHANGE_PART_DATA", data);
    },
    changeBuildData({ commit }, data) {
        commit("CHANGE_BUILD_DATA", data);
    },
    changeBuildDataLocale({ commit }, fieldData) {
        commit("CHANGE_BUILD_DATA_LOCALE", fieldData);
    },
    clearBuildFormData({ commit }, buildData) {
        commit("CLEAR_BUILD_FORM_DATA", buildData);
    },
    async fetchPartsDetails({ dispatch }, { currentRegionId }) {
        store.commit("Application/SET_IS_LOADING", true);
        dispatch("setIsLoading", true);
        try {
            await dispatch("fetchPartsCategories", { currentRegionId });
        } catch (error) {
            onError(error);
        } finally {
            store.commit("Application/SET_IS_LOADING", false);
            dispatch("setIsLoading", false);
        }
    },
    async fetchBuidData({ dispatch }, { resourceSlug, buildId, currentRegionId }) {
        store.commit("Application/SET_IS_LOADING", true);
        dispatch("setIsLoading", true);
        try {
            const { data: buildData } = await axios(`/${ resourceSlug }/${ buildId }?relationships=files`);

            const name = JSON.parse(buildData.name) || {};
            const description = JSON.parse(buildData.description) || {};
            const isPublished = Boolean(Number(buildData.is_published));
            const isFromProduct = Boolean(Number(buildData.is_from_product));

            const buildFormData = {
                ...buildData,
                name,
                description,
                slug: buildData.slug,
                builds_types_id: buildData.builds_types_id,
                is_published: isPublished,
                is_from_product: isFromProduct
            };

            dispatch("setBuilData", buildFormData);
            await dispatch("fetchPartsCategories", { currentRegionId });
        } catch (error) {
            onError(error);
        }
    },
    async fetchPartsCategories({ dispatch }, { currentRegionId }) {
        try {
            const { data: partsCategories } = await axios(`/builder/regions/${ currentRegionId }/categories`);
            dispatch("setPartsCategories", partsCategories);
            store.commit("Application/SET_IS_LOADING", false);
            dispatch("setIsLoading", false);
        } catch (error) {
            onError(error);
        }
    },
    async fetchBuildParts({ dispatch, state }, part) {
        const parts = new Map();

        try {
            const { builder_category_id: builderCategoryId } = part;
            const { data: products } = await axios(`/builder-products?q=(builder_category_id:${ builderCategoryId })&limit=200`);
                        
            if (!state.parts.has[builderCategoryId]) {
                parts.set(builderCategoryId, products);
                const merged = new Map([...state.parts, ...parts]);
                dispatch("setParts", merged);
            }

            return state.parts;
        } catch (error) {
            onError(error);
        }
    },
    async fetchPartsVariants({ dispatch }, parts) {
        let partsVariants = [];

        try {
            for (const product of parts) {
                const { product_id: productId } = product;
                const { data: variants } = await axios(`/builder-products-variants?q=(product_id:${ productId })&limit=200`);

                partsVariants = [...partsVariants, ...variants];
            }

            dispatch("setPartsVariants", partsVariants);
        } catch (error) {
            onError(error);
        } finally {
            store.commit("Application/SET_IS_LOADING", false);
            dispatch("setIsLoading", false);
        }
    },
    async updateRecommendedBuild(context, updateBuild) {
        try {
            await axios({
                url: `/recommended-builds/${ state.buildFormData.id }`,
                method: "PUT",
                data: updateBuild
            });

            Vue.notify({
                title: "Updated",
                text: "Recommended builds was updated successfully",
                type: "success"
            });
        } catch (error) {
            onError(error);
        }
    },
    async createRecommendedBuild(context, newBuild) {
        try {
            await axios({
                url: "/recommended-builds",
                method: "POST",
                data: newBuild
            });

            Vue.notify({
                title: "Created",
                text: "Recommended builds was created successfully",
                type: "success"
            });
        } catch (error) {
            onError(error);
        }
    },
    clearData({ dispatch }) {
        const buildFormData = {
            name: "",
            description: "",
            cpu: "",
            is_published: false,
            is_from_product: false,
            price: 0,
            slug: "",
            details: []
        };

        dispatch("clearBuildFormData", buildFormData);
    },
    clean({ commit }) {
        commit("CLEAN");
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
