<template>
    <div class="product-variant">
        <foldable-resource-card>
            <template v-slot:image>
                <div class="placeholder_image" />
            </template>

            <template v-slot:primary-button-label>
                New
            </template>

            <template v-slot:content>
                <slot />
            </template>
        </foldable-resource-card>
    </div>
</template>

<script>
import FoldableResourceCard from "@c/foldable-resource-card";

export default {
    name: "ProductVariant",
    components: {
        FoldableResourceCard
    },
    data() {
        return {
            shouldToggleForm: false
        }
    }
}
</script>

<style lang="scss">
.product-variant {
    .placeholder_image {
        background-color: rgb(201, 201, 201);
        width: 72px;
        height: 72px;
        border-radius: 3px;
        cursor: pointer;
    }
}
</style>
