export const FORMS = {
    DEFAULT_MAX_LENGTH_INPUT: 255,
    DEFAULT_MAX_LENGTH_TEXTAREA: 32000
}

export const specialResources = [
    "recommended-builds", 
    "games", 
    "rules"
]
