<template>
    <form class="resource-form bld-form" @submit.prevent>
        <div class="row">
            <div class="col">
                <h3 class="title">
                    Product Options on BLD
                </h3>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group-multiselect">
                    <label class="form-label"> Builder Category </label>
                    <multiselect
                        v-model="bldCategory"
                        :options="bldCategories"
                        :allow-empty="false"
                        deselect-label="Can't remove this value"
                        track-by="id"
                        label="name"
                    />
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group-multiselect">
                    <label class="form-label">Builder Subcategory</label>
                    <multiselect
                        v-model="seleted"
                        :options="options"
                        :allow-empty="false"
                        deselect-label="Can't remove this value"
                        track-by="value"
                        label="label"
                    />
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group form-group-default">
                    <label class="form-label switch-label">Max Quantity:</label>
                    <input
                        v-model="maxQuantity"
                        name="max-quantity"
                        class="form-control"
                        type="number"
                        min="0"
                    >
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label switch-label">Is Published:</label>
                <switches
                    v-model="isPublished"
                    name="published"
                    class="published-switch"
                    :color="isPublished ? 'green' : 'default'"
                    theme="bulma"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label switch-label">Is Published (AMD):</label>
                <switches
                    v-model="isPublishedAmd"
                    name="published"
                    class="published-switch"
                    :color="isPublishedAmd ? 'green' : 'default'"
                    theme="bulma"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label switch-label">Is Published (Intel):</label>
                <switches
                    v-model="isPublishedIntel"
                    name="published"
                    class="published-switch"
                    :color="isPublishedIntel ? 'green' : 'default'"
                    theme="bulma"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label switch-label">is In Last Chance Modal:</label>
                <switches
                    v-model="isInLastChanceModal"
                    name="published"
                    class="published-switch"
                    :color="isInLastChanceModal ? 'green' : 'default'"
                    theme="bulma"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label switch-label">Is Hidden:</label>
                <switches
                    v-model="isHidden"
                    name="published"
                    class="published-switch"
                    :color="isHidden ? 'green' : 'default'"
                    theme="bulma"
                />
            </div>
        </div>

        <form-actions
            :is-loading="false"
            @save="$_submitResource(preparedData)"
        >
            <template v-slot:cancel-button>
                <span />
            </template>
            <template v-slot:submit-label>
                Save Product
            </template>
        </form-actions>

        <div v-if="attributes.length">
            <div class="row">
                <div class="col">
                    <h3 class="title">
                        Attributes
                    </h3>
                </div>
            </div>

            <div class="row">
                <attributes
                    :attributes="attributes"
                    :submit-url="attributesSubmitUrl"
                    @form-submitted="onAttributesFormSubmitted"
                />
            </div>
        </div>


        <div v-if="variants.length">
            <div class="row">
                <div class="col">
                    <h3 class="title">
                        Variant Options on BLD
                    </h3>
                </div>
            </div>
            <builder-variants locale="en_US" :variants="variants" />
        </div>
    </form>
</template>

<script>

import Switches from "vue-switches";
import BuilderVariants from "./variants";
import FormActions from "@c/form-actions.vue";
import { mapState, mapGetters } from "vuex";
import formsMixin from "@m/formsMixin";
import attributeFormMixin from "@m/attributeFormMixin";
import Attributes from "./attributes.vue";
export default {
    name: "BLDForm",
    components: {
        Switches,
        BuilderVariants,
        FormActions,
        Attributes
    },
    mixins: [
        formsMixin,
        attributeFormMixin
    ],
    data() {
        return {
            productId: this.$route.params.id,
            submitMethod: "PUT",
            bldCategories: [],
            bldSubCategories: [
                "Keyboards",
                "Mouse",
                "Audio",
                "Accessories",
                "Monitors",
                "Lighting",
                "Capture Card"
            ],
            selectedSubCategory: null,
        }
    },
    computed: {
        ...mapState({
            formData: state => state.BLDForm.formData,
            variants: state => state.BLDForm.variants,
            currentRegionId: state => state.Region.currentRegionId,
            attributes: state => state.BLDForm.attributes
        }),
        ...mapGetters({
            preparedFormData: "BLDForm/preparedFormData",
            selectOptions: "BLDForm/getSelectOptions"
        }),
        maxQuantity: {
            get() {
                return this.formData.max_quantity;
            },
            set(value) {
                this.$store.commit("BLDForm/SET_QUANTITY", value);
            }
        },
        isPublished: {
            get() {
                return this.formData.is_published;
            },
            set(value) {
                this.$store.commit("BLDForm/SET_IS_PUBLISHED", value);
            }
        },
        isPublishedAmd: {
            get() {
                return this.formData.is_published_for_amd;
            },
            set(value) {
                this.$store.commit("BLDForm/SET_IS_PUBLISHED_AMD", value);
            }
        },
        isPublishedIntel: {
            get() {
                return this.formData.is_published_for_intel;
            },
            set(value) {
                this.$store.commit("BLDForm/SET_IS_PUBLISHED_INTEL", value);
            }
        },
        isInLastChanceModal: {
            get() {
                return this.formData.is_in_last_chance_modal;
            },
            set(value) {
                this.$store.commit("BLDForm/SET_IS_IN_LAST_CHANCE_MODAL", value);
            }
        },
        isHidden: {
            get() {
                return this.formData.is_hidden;
            },
            set(value) {
                this.$store.commit("BLDForm/SET_IS_HIDDEN", value);
            }
        },
        bldCategory: {
            get() {
                const { builder_category_id: builderCategoryId } = this.formData;
                return this.bldCategories.find(({ id }) => id == builderCategoryId);
            },
            set(value) {
                this.$store.commit("BLDForm/SET_BLD_CATEGORY", value.id);
            }
        },
        options: {
            get() {
                return this.selectOptions;
            },
            set(value) {
                this.$store.commit("BLDForm/SET_OPTIONS", value.label);
            }
        },
        seleted: {
            get() {
                const subCategoryId = this.formData.subcategory;
                return this.selectOptions.find(({ label }) => label.toLowerCase() === subCategoryId);
            },
            set(value) {
                this.$store.commit("BLDForm/SET_TYPE", value.label.toLowerCase());
            }
        },
        bldSubCategory: {
            get() {
                const subCategoryId = this.formData.subcategory;
                return this.bldSubCategories.map((subCategory) => subCategory.toLowerCase() === subCategoryId);
            },
            set(value) {
                this.$store.commit("BLDForm/SET_BLD_SUB_CATEGORY", value.toLowerCase());
            }
        },
        fetchUrl() {
            const { productId } = this.formData;
            return `/builder/regions/${currentRegionId}/products/${productId}`;
        },
        submitUrl() {
            return `/builder/regions/${this.currentRegionId}/products/${this.productId}`;
        },
        attributesSubmitUrl() {
            const { product_id: productId } = this.formData;
            return `/products/${productId}/attributes`;
        }
    },
    created() {
        this.$store.dispatch("BLDForm/fetchData", this.productId);
        this.selectedSubCategory = this.formData.subcategory;
        this.fetchBLDCategories();
    },
    beforeDestroy() {
        this.$store.dispatch("BLDForm/clean");
    },
    methods: {
        preparedData() {
            const subCategory = this.seleted ? this.seleted.label.toLowerCase() : null;
            this.preparedFormData.subcategory = subCategory; 
            return this.preparedFormData;
        },
        onAttributesFormSubmitted(data) {
            const attributesFormData = this.$_prepareAttributes(data);
            this.$store.commit("Application/SET_IS_LOADING", true);
            axios({
                url: this.attributesSubmitUrl,
                method: "PUT",
                data: attributesFormData
            }).then(() => {
                this.$notify({
                    text: `Attributes form saved successfully.`,
                    type: "success"
                });
                this.$store.dispatch("BLDForm/updateAttributes");
            }).catch((error) => {
                this.$notify({
                    text: error.response.data.errors.message,
                    type: "error"
                });
            }).finally(() => {
                this.$store.commit("Application/SET_IS_LOADING", false);
            });
        },
        fetchBLDCategories() {
            axios(`/builder/regions/${this.currentRegionId}/categories`).then(({ data }) => this.bldCategories = data)
        }
    }
}
</script>

<style lang="scss">
.bld-form {
    .published-switch {
        margin: 20px 15px 0 70px;
    }

    .switch-label {
        width: 118px;
    }
}
</style>
