const state = {
    currentLocale: {
        locale: "de_DE",
        name: "Deutsch"
    }
};

const mutations = {
    SET_CURRENT_LOCALE(state, locale) {
        state.currentLocale = locale;
    }
};

const actions = {
    setCurrentLocale({ commit }, locale) {
        commit("SET_CURRENT_LOCALE", locale);
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
