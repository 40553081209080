<template>
    <button class="edit-button btn-outline-secondary" @click="confirmDelete">
        Delete
    </button>
</template>

<script>
import ResourceDeleteModal from "@c/modals/resource-delete-modal";

export default {
    name: "DeleteButton",
    props: {
        deleteTitle: {
            type: String,
            default: "this"
        }
    },
    methods: {
        confirmDelete() {
            this.$modal.show(ResourceDeleteModal, {
                buttons: [{
                    title: "Cancel",
                    handler: () => {
                        this.$modal.hide("delete-resource");
                        this.$emit("delete-cancel");
                    }
                }, {
                    title: "Confirm",
                    class: "btn-danger",
                    handler: () => {
                        this.$emit("delete-confirmed");
                        this.$modal.hide("delete-resource");
                    }
                }],
                deleteEntity: this.deleteTitle
            }, {
                adaptive: true,
                clickToClose: false,
                height: "auto",
                name: "delete-resource",
                width: 500
            });
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
