<template>
    <foldable-resource-card
        v-if="!isLoading"
        :toggle-content="showContent"
        :should-show-image="false"
    >
        <template v-slot:label>
            <div class="details">
                <span>
                    <strong>Category:</strong> {{ categoryName }}
                </span>
                <span>
                    <strong>Name:</strong> {{ name }}
                </span>
                <span>
                    <strong>Variant name:</strong> {{ variantName }}
                </span>
            </div>
        </template>

        <template v-slot:primary-button>
            <button type="button" @click="getPartDetails">
                Edit
            </button>
        </template>

        <template v-slot:secondary-button>
            <delete-button :delete-title="name" @delete-confirmed="$emit('detele-part', index)" />
        </template>

        <template v-slot:content>
            <part-form
                :current-product="product"
                :current-variant="variant"
                :current-index="index"
                :current-builder-category-id="builderCategoryId"
            />
        </template>
    </foldable-resource-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { isJson } from "@/utils/helpers";
import FoldableResourceCard from "@c/foldable-resource-card";
import DeleteButton from "@c/delete-button";
import PartForm from "./part-form";

export default {
    name: "PartDetailCard",
    components: {
        FoldableResourceCard,
        DeleteButton,
        PartForm
    },
    props: {
        categoryName: {
            type: [String, Number],
            default: ""
        },
        builderCategoryId: {
            type: [Number, String],
            default: ""
        },
        product: {
            type: Object,
            default() {
                return {};
            }
        },
        variant: {
            type: Object,
            default() {
                return {};
            }
        },
        index: {
            type: [Number, String],
            default: 0
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showContent: false
        }
    },
    computed: {
        ...mapState({
            currentLocale: state => state.Locale.currentLocale.locale
        }),
        name() {
            if (!this.isLoading) {
                return isJson(this.product.product_name) ? this.checkCurrentLocale(this.product.product_name) : this.product.product_name;
            }

            return "";
        },
        variantName() {
            if (!this.isLoading) {
                return isJson(this.product.variant_name) ? this.checkCurrentLocale(this.product.product_name) : this.product.variant_name;
            }

            return "";
        }
    },
    methods: {
        ...mapActions({
            fetchBuildParts: "BuildForm/fetchBuildParts"
        }),
        async getPartDetails() {
            await this.fetchBuildParts(this.product);
            this.showContent = !this.showContent;
        },
        checkCurrentLocale(name) {
            return JSON.parse(name)[this.currentLocale] ? 
                JSON.parse(name)[this.currentLocale] : JSON.parse(name)["en_US"];
        }
    }
}
</script>

<style lang="scss" scoped>
.details {
    display: flex;
    flex-direction: column;
}
</style>>
