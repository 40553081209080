<template>
    <div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-4">
                <div class="form-group-multiselect">
                    <label class="form-label">Category</label>
                    <multiselect
                        v-model="category"
                        :options="categories"
                        :allow-empty="false"
                        deselect-label="Can't remove this value"
                        track-by="name"
                        label="name"
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-4">
                <div class="form-group-multiselect">
                    <label class="form-label">Product</label>
                    <multiselect
                        v-model="product"
                        :options="localeProducts"
                        :allow-empty="false"
                        deselect-label="Can't remove this value"
                        track-by="name"
                        label="name"
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-4">
                <div :class="{ 'border-danger': errors.has('quantity') }" class="form-group form-group-default">
                    <label :class="{'text-danger': errors.has('quantity') }">
                        Quantity
                        <span v-if="errors.has('quantity')">(required)</span>
                    </label>
                    <input
                        v-model="quantity"
                        v-validate="'required'"
                        name="name"
                        data-vv-validate-on="blur"
                        class="form-control"
                        type="text"
                        autofocus
                    >
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-4">
                <div class="form-group-multiselect">
                    <label class="form-label">Variant</label>
                    <multiselect
                        v-model="variant"
                        :options="variants"
                        :allow-empty="false"
                        deselect-label="Can't remove this value"
                        track-by="name"
                        label="name"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { isJson } from "@/utils/helpers";
import isEmpty from "lodash/isEmpty";

export default {
    name: "PartForm",
    props: {
        currentProduct: {
            type: Object,
            default() {
                return {};
            }
        },
        currentVariant: {
            type: Object,
            default() {
                return {};
            }
        },
        currentIndex: {
            type: [Number, String],
            default: 0
        },
        currentBuilderCategoryId: {
            type: [Number, String],
            default: 0
        }
    },
    data() {
        return {
            variants: []
        }
    },
    computed: {
        ...mapGetters({
            getParts: "BuildForm/getParts",
            getbuildFormData: "BuildForm/getbuildFormData",
            getPartsCategories: "BuildForm/getPartsCategories"
        }),
        ...mapState({
            currentLocale: state => state.Locale.currentLocale.locale,
            currentRegionId: state => state.Region.currentRegionId
        }),
        buildPartsDetails() {
            return this.getbuildFormData.details;
        },
        categories() {
            return this.getPartsCategories;
        },
        parts() {
            return this.getParts;
        },
        selectedProduct() {
            return this.currentProduct
        },
        selectedProductVariant() {
            return this.currentVariant;
        },
        products() {
            return this.parts.get(this.category.id)
        },
        localeProducts() {
            return this.products.map(part => {
                return {
                    ...part,
                    name: isJson(part.name) ? this.checkCurrentLocale(part.name) : part.name
                }
            })
        },
        partDetails() {
            if (this.selectedProduct.product_id == 0) {
                return this.selectedProduct;
            }

            if (!isEmpty(this.selectedProduct)) {
                const product = this.buildPartsDetails.find(({ product_id: productId }) => productId == this.selectedProduct.product_id);
                return product;
            }
            const product = this.buildPartsDetails.find(({ product_id: productId }) => productId == this.selectedProductVariant.product_id);
            return product;
        },
        buildPartIndex() {
            return this.currentIndex;
        },
        category: {
            get() {
                return this.categories.find(({ id }) => id == this.currentBuilderCategoryId);
            },
            async set({ id }) {
                await this.fetchBuildParts({ builder_category_id: id })
                this.changePartData({ index: this.buildPartIndex, property: "builder_category_id", value: id });
                this.changePartData({ index: this.buildPartIndex, property: "product_id", value: 0 });
                this.changePartData({ index: this.buildPartIndex, property: "variant_id", value: 0 });
            }
        },
        product: {
            get() {
                if (this.partDetails.product_id == 0) {
                    return "";
                }

                const { product_id: productId } = this.partDetails;
                const product = this.products.find(({ product_id: id }) => id == productId);
                return { ...product, name: isJson(product.name) ? this.checkCurrentLocale(product.name) : product.name };
            },
            async set({ product_id: productId }) {
                await this.getVariants(productId);
                await this.changePartData({ index: this.buildPartIndex, property: "product_id", value: productId });
                await this.changePartData({ index: this.buildPartIndex, property: "variant_id", value: this.variants[0].product_variant_id });
            }
        },
        quantity: {
            get() {
                const { quantity } = this.partDetails;
                return quantity;
            },
            set(value) {
                this.changePartData({ index: this.buildPartIndex, property: "quantity", value });
            }
        },
        variant: {
            get() {
                const { variant_id: variantId } = this.partDetails;
                return this.variants.find(({ product_variant_id: id }) => id == variantId);
            },
            set({ product_variant_id: productVariantId }) {
                this.changePartData({ index: this.buildPartIndex, property: "variant_id", value: productVariantId });
            }
        }
    },
    mounted() {
        this.getVariants();
    },
    methods: {
        ...mapActions({
            changePartData: "BuildForm/changePartData",
            fetchBuildParts: "BuildForm/fetchBuildParts"
        }),
        async getVariants(productId = null) {
            const { data: variants } = await axios(`/builder-products-variants?q=(product_id:${
                !productId ? this.selectedProduct.product_id : productId
            }, ,region_id:${ this.currentRegionId }))`);

            const parsedVariants = variants.map((variant) => {
                return this.parsedVariantName(variant, this.addColor(variant));
            })

            this.variants = parsedVariants;
        },
        parsedVariantName(variant, option = "") {
            return { ...variant, name: `${ JSON.parse(variant.name)[this.currentLocale] }${ option }` }
        },
        addColor({ option }) {
            return option.name == "colors" ? `-${ option.value }` : "";
        },
        checkCurrentLocale(name) {
            return JSON.parse(name)[this.currentLocale] ? 
                JSON.parse(name)[this.currentLocale] : JSON.parse(name)["en_US"];
        }
    }
}
</script>
