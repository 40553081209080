import store from "@/store";

const getDefaultStates = () => {
    return {
        formData: {
            category_id: "",
            is_published: false,
            is_best_seller: false,
            is_published_in_product_list: false,
            cpu_type: "",
            gpu_type: "",
            store_brand: "",
            subtitle: ""
        },
        variants: [],
        brands: [
            "AMD", 
            "AORUS", 
            "ASUS", 
            "ASROCK", 
            "EVGA", 
            "GIGABYTE", 
            "G SKILL", 
            "Kingston", 
            "Intel", 
            "MSI", 
            "NZXT", 
            "PNY", 
            "Seasonic", 
            "TeamGroup", 
            "Western Digital"
        ]
    }
};

const state = getDefaultStates();

const mutations = {
    SET_FORM_DATA(state, data) {
        state.formData = data;
    },
    SET_IS_PUBLISHED(state, isPublished) {
        state.formData = {
            ...state.formData,
            is_published: isPublished
        };
    },
    SET_GPU_TYPE(state, gpuType) {
        state.formData = {
            ...state.formData,
            gpu_type: gpuType
        };
    },
    SET_CPU_TYPE(state, cpuType) {
        state.formData = {
            ...state.formData,
            cpu_type: cpuType
        };
    },
    SET_IS_BEST_SELLER(state, isBestSeller) {
        state.formData = {
            ...state.formData,
            is_best_seller: isBestSeller
        };
    },
    SET_CATEGORY_ID(state, categoryId) {
        state.formData = {
            ...state.formData,
            category_id: categoryId
        };
    },
    SET_SUB_TITLE(state, value) {
        state.formData = {
            ...state.formData,
            subtitle: value
        };
    },
    SET_VARIANTS(state, variants) {
        state.variants = variants
    },
    SET_STORE_BRAND(state, value) {
        state.formData = {
            ...state.formData,
            store_brand: value
        };
    },
    SET_SHOW_IN_PRODUCT_LIST(state, value) {
        state.formData = {
            ...state.formData,
            is_published_in_product_list: value
        };
    },
    CLEAN(state) {
        Object.assign(state, getDefaultStates());
    }
};

const actions = {
    updateVariants({ commit, state }) {
        const defaultRegionId = store.state.Region.currentRegionId;
        axios.get(`/regions/${defaultRegionId}/products/${state.formData.products_id}/variants`)
            .then(({ data: variants }) => {
                commit("SET_VARIANTS", variants);
            });
    },
    parseData({ commit, state }) {
        const parsedData = {
            ...state.formData,
            is_published: Boolean(Number(state.formData.is_published)),
            is_best_seller: Boolean(Number(state.formData.is_best_seller)),
            is_published_in_product_list: Boolean(Number(state.formData.is_published_in_product_list)),
            cpu_type: state.formData.cpu_type,
            gpu_type: state.formData.gpu_type,
            subtitle: state.formData.subtitle
        };
        commit("SET_FORM_DATA", parsedData);
    },
    clean({ commit }) {
        commit("CLEAN");
    }
};

const getters = {
    variantProductLength(state) {
        return state.formData.product_variants.length;
    },
    getSelectOptions(state) {
        return state.brands.map((brand, index) => {
            return { value: index + 1, label: brand };
        });
    },
    preparedFormData(state) {
        return {
            ...state.formData,
            is_published: Number(state.formData.is_published),
            is_best_seller: Number(state.formData.is_best_seller)
        };
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
