const getDefaultStates = () => {
    return {
        formData: {
            id: "",
            name: {},
            stock: 0,
            reserved_quantity: 0,
            stock_threshold: 0,
            is_published: false,
            is_oversellable: false,
            is_default: false,
            products_id: ""
        }
    }
};

const state = getDefaultStates();

const mutations = {
    SET_FORM_DATA(state, data) {
        state.formData = data;
    },
    SET_NAME(state, { locale, name }) {
        state.formData = {
            ...state.formData,
            name: {
                ...state.formData.name,
                [locale]: name
            }
        }
    },
    SET_STOCK(state, stock) {
        state.formData = {
            ...state.formData,
            stock
        };
    },
    SET_STOCK_THRESHOLD(state, stockThreshold) {
        state.formData = {
            ...state.formData,
            stock_threshold: stockThreshold
        };
    },
    SET_RESERVED_QUANTITY(state, quantity) {
        state.formData = {
            ...state.formData,
            reserved_quantity: quantity
        };
    },
    SET_IS_PUBLISHED(state, published) {
        state.formData = {
            ...state.formData,
            is_published: published
        };
    },
    SET_IS_OVERSELLABLE(state, isOversellable) {
        state.formData = {
            ...state.formData,
            is_oversellable: isOversellable
        };
    },
    SET_IS_DEFAULT(state, isDefault) {
        state.formData = {
            ...state.formData,
            is_default: isDefault
        };
    },
    SET_COLORS(state, colors) {
        state.formData = {
            ...state.formData,
            colors
        };
    },
    SET_DESCRIPTION(state, { locale, description }) {
        state.formData = {
            ...state.formData,
            description: {
                ...state.formData.description,
                [locale]: description
            }
        }
    },
    SET_PRODUCT_ID(state, productId) {
        state.formData = {
            ...state.formData,
            products_id: productId
        };
    },
    CLEAN(state) {
        Object.assign(state, getDefaultStates());
    }
};

const actions = {
    setFormData({ commit }, formData) {
        let parsedVariantName = {};
        if (formData.name) {
            parsedVariantName = JSON.parse(formData.name) || {};
        }

        const parsedFormData = {
            ...formData,
            name: parsedVariantName
        };

        commit("SET_FORM_DATA", parsedFormData);
    },
    setDescription({ commit }, { locale, description }) {
        commit("SET_DESCRIPTION", { locale, description });
    },
    setName({ commit }, { locale, name }) {
        commit("SET_NAME", { locale, name });
    },
    clean({ commit }) {
        commit("CLEAN");
    }
};

const getters = {

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
