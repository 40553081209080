<template>
    <div class="file-preview">
        <resource-card>
            <template v-slot:image>
                <img v-if="file.url" :src="file.url">
                <div v-else class="placeholder_image" />
            </template>
            <template v-slot:label>
                <span> <strong> {{ label || file.field_name }} </strong> </span>
            </template>
            <template v-slot:secondary-button>
                <delete-button :delete-title="`'${file.name}'`" @delete-confirmed="$emit('delete-clicked')" />
            </template>
        </resource-card>
    </div>
</template>


<script>

import ResourceCard from "@c/resource-card"
import DeleteButton from "@c/delete-button";

export default {
    name: "FilePreview",
    components: {
        ResourceCard,
        DeleteButton
    },
    props: {
        file: {
            type: Object,
            default: () => {
                return {}
            }
        },
        label: {
            type: String,
            default: ""
        }
    }
}
</script>

<style lang="scss">
.file-preview {
    img {
        width: 150px;
        height: 150px;
        object-fit: contain;
        object-position: center;
    }

    .placeholder_image {
        background-color: rgb(201, 201, 201);
        width: 150px;
        height: 150px;
        border-radius: 3px;
    }
}
</style>

