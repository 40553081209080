<template>
    <div class="row">
        <div class="col-12 col-md-6">
            <h3>Rule</h3>
            <language-locale-field />

            <div :class="{ 'border-danger': errors.has('rule') }">
                <label :class="{'text-danger': errors.has('rule') }">
                    Rules type
                    <span v-if="errors.has('rule')">(required)</span>
                </label>
                <multiselect
                    v-model="rule"
                    :options="rules"
                    :allow-empty="false"
                    deselect-label="Can't remove this value"
                    placeholder="Pick a value"
                    track-by="name"
                    label="name"
                />
            </div>

            <div :class="{ 'border-danger': errors.has('params') }" class="form-group form-group-default">
                <label :class="{'text-danger': errors.has('params') }">
                    Params
                    <span v-if="errors.has('params')">(required)</span>
                </label>
                <textarea
                    v-model="formData.params"
                    v-validate="'required'"
                    name="params"
                    data-vv-validate-on="blur"
                    class="form-control"
                    rows="3"
                />
            </div>

            <div :class="{ 'border-danger': errors.has('description') }" class="form-group form-group-default">
                <label :class="{'text-danger': errors.has('description') }">
                    Description
                    <span v-if="errors.has('description')">(required)</span>
                </label>
                <textarea
                    v-model="formData.description"
                    v-validate="'required'"
                    rule
                    name="description"
                    data-vv-validate-on="blur"
                    class="form-control"
                    type="text"
                />
            </div>

            <div :class="{ 'border-danger': errors.has('outputMessage') }" class="form-group form-group-default">
                <label :class="{'text-danger': errors.has('outputMessage') }">
                    Output message
                    <span v-if="errors.has('outputMessage')">(required)</span>
                </label>
                <textarea
                    v-model="outputMessage"
                    v-validate="'required'"
                    name="outputMessage"
                    data-vv-validate-on="blur"
                    class="form-control"
                />
            </div>

            <button
                class="btn btn-success btn-lg btn-block"
                type="button"
                @click="updateOrCreate"
            >
                {{ btnMsg }}
            </button>
        </div>
        <div class="col-12 col-md-6">
            <h3>Trigger</h3>
            <div v-for="(triggers, index) in rule_triggers" :key="triggers.id">
                <div class="d-flex align-items-baseline mb-3">
                    <div>
                        <div class="form-check form-check-inline">
                            <input
                                :id="`add-${index}`"
                                v-model="rule_triggers[index].action"
                                class="form-check-input"
                                type="checkbox"
                                value="add"
                            >
                            <label class="form-check-label" :for="`add-${index}`">add</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                :id="`update-${index}`"
                                v-model="rule_triggers[index].action"
                                class="form-check-input"
                                type="checkbox"
                                value="update"
                            >
                            <label class="form-check-label" :for="`update-${index}`">update</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                :id="`remove-${index}`"
                                v-model="rule_triggers[index].action"
                                class="form-check-input"
                                type="checkbox"
                                value="remove"
                            >
                            <label class="form-check-label" :for="`remove-${index}`">remove</label>
                        </div>
                    </div>
                    <button
                        class="btn btn-danger"
                        type="button"
                        @click="deleteTrigger(index)"
                    >
                        remove
                    </button>
                </div>
                <div :class="{ 'border-danger': errors.has('quantity') }" class="form-group form-group-default">
                    <label :class="{'text-danger': errors.has('quantity') }">
                        Condition
                        <span v-if="errors.has('quantity')">(required)</span>
                    </label>
                    <textarea
                        v-model="rule_triggers[index].condition"
                        v-validate="'required'"
                        name="name"
                        data-vv-validate-on="blur"
                        class="form-control"
                        type="text"
                    />
                </div>
                <div :class="{ 'border-danger': errors.has('outputMessageTrigger') }" class="form-group form-group-default">
                    <label :class="{'text-danger': errors.has('outputMessageTrigger') }">
                        Output message
                        <span v-if="errors.has('outputMessageTrigger')">(required)</span>
                    </label>
                    <textarea
                        v-validate="'required'"
                        :value="checkCurrentLocale(rule_triggers[index].output_message)"
                        name="outputMessageTrigger"
                        data-vv-validate-on="blur"
                        class="form-control"
                        @input="$event => setTriggerOutput($event.target.value, index)"
                    />
                </div>
                <div class="mb-5" :class="{ 'border-danger': errors.has('rule') }">
                    <label :class="{'text-danger': errors.has('rule') }">
                        Trigger Categories
                        <span v-if="errors.has('rule')">(required)</span>
                    </label>
                    <multiselect
                        v-model="rule_triggers[index].categories"
                        :allow-empty="false"
                        :options="partsCategories"
                        :multiple="true"
                        :taggable="true"
                        deselect-label="Can't remove this value"
                        placeholder="Pick a value"
                        track-by="id"
                        label="name"
                    />
                </div>
            </div>
            <button
                class="btn btn-success btn-lg btn-block"
                type="button"
                @click="addTrigger"
            >
                Add
            </button>
        </div>
    </div>
</template>

<script>
import _pick from "lodash/pick";
import _isEmpty from "lodash/isEmpty";
import { mapState, mapGetters, mapActions } from "vuex";
import { isJson } from "@/utils/helpers";
import { composeResources } from "@/utils/helpers";
import LanguageLocaleField from "@c/language-locale-field";

export default {
    name: "RolesForm",
    components: {
        LanguageLocaleField
    },
    data() {
        return {
            formData: {
                rule_type_id: "",
                params: "",
                description: "",
                output_message: "{}"
            },
            rule_triggers: [],
            rules: []
        }
    },
    computed: {
        ...mapState({
            resources: state => composeResources(state.Application.resources),
            currentLocale: state => state.Locale.currentLocale.locale,
            currentRegionId: state => state.Region.currentRegionId
        }),
        ...mapGetters({
            getPartsCategories: "BuildForm/getPartsCategories"
        }),
        partsCategories() {
            return this.getPartsCategories;
        },
        resource() {
            return this.resources.find(resource => resource.slug == this.$route.params.resource);
        },
        resourceSlug() {
            const { slug } = this.resource;
            return slug;
        },
        ruleId() {
            return this.$route.params.id;
        },
        btnMsg() {
            return this.ruleId ? "Update" : "Create";
        },
        currentRule() {                
            const rule = this.rules.find(({ id }) => id === this.formData.rule_type_id);
            return rule;
        },
        rule: {
            get() {
                return this.currentRule;
            },
            set({ id }) {
                this.formData.rule_type_id = id;
            }
        },
        outputMessage: {
            get() {
                return isJson(this.formData.output_message) ? 
                    this.checkCurrentLocale(this.formData.output_message) : this.formData.output_message
            },
            set(value) {
                const newMessage = {
                    [this.currentLocale]: value
                }

                this.formData.output_message = 
                    JSON.stringify(Object.assign({}, isJson(this.formData.output_message) ? JSON.parse(this.formData.output_message) : "", newMessage));
            }
        }
    },
    mounted() {
        this.getRulesAndCategories();
    },
    methods: {
        ...mapActions({
            fetchPartsCategories: "BuildForm/fetchPartsCategories"
        }),
        async getRulesAndCategories() {
            await this.fetchPartsCategories({
                currentRegionId: this.currentRegionId
            });

            await this.getRulesType();
        
            if (this.ruleId) {
                await this.getRule(this.ruleId);
            }
        },
        async getRulesType() {
            const { data: RulesType } = await axios("/rule-types");

            this.rules = RulesType;
        },
        async getRule(id) {
            const { data: RuleData } = await axios.get(`/${ this.resourceSlug }/${ id }`);

            const mergedFormData = Object.assign(
                {}, 
                this.formData, 
                _pick(RuleData, ["rule_type_id", "params", "description", "output_message"])
            );

            this.formData = mergedFormData;

            if (_isEmpty(this.rule_triggers)) {
                this.rule_triggers = []
            }

            this.rule_triggers = RuleData.rule_triggers.map(trigger => {
                return {
                    ...trigger,
                    categories: this.partsCategories
                        .filter(({ category_id: categoryId }) => trigger.categories.includes(Number(categoryId))),
                    action: trigger.action.split(",")
                };
            });
        },
        checkCurrentLocale(name) {
            return JSON.parse(name)[this.currentLocale] ? 
                JSON.parse(name)[this.currentLocale] : JSON.parse(name)["en_US"];
        },
        setTriggerOutput(value, index) {
            const newMessage = {
                [this.currentLocale]: value
            }

            this.rule_triggers[index].output_message = 
                JSON.stringify(Object.assign({}, 
                    isJson(this.rule_triggers[index].output_message) ? 
                        JSON.parse(this.rule_triggers[index].output_message) : this.rule_triggers[index].output_message, 
                    newMessage
                ));
        },
        addTrigger() {
            this.rule_triggers = [...this.rule_triggers, {
                action: [],
                condition: "",
                id: null,
                output_message: "{}"
            }];
        },
        deleteTrigger(index) {
            this.rule_triggers = this.rule_triggers.filter((_, i) => i !== index); 
        },
        async updateOrCreate() {
            const triggers = this.rule_triggers.map(trigger => {
                return {
                    ...trigger,
                    categories: trigger.categories.map(category => category.category_id),
                    action: trigger.action.join(",")
                };
            });

            const mergeRuleAndTriggers = {
                ...this.formData,
                triggers
            }

            try {
                await axios({
                    url: this.ruleId ? `/rules/${ this.ruleId }` : `/rules`,
                    method: this.ruleId ? "PUT" : "POST",
                    data: mergeRuleAndTriggers
                });

                this.$notify({
                    title: "Updated",
                    text: this.ruleId ? "Rule was updated successfully" : "Rule was created successfully",
                    type: "success"
                });               
            } catch (error) {
                this.onError(error);
            }
        },
        async onError(error) {
            const { data: { errors } } = error.response;
            this.$notify({
                title: errors.type,
                text: errors.message,
                type: "error"
            });
        }
    }
}
</script>
