<template>
    <foldable-resource-card :toggle-content="toggleContent">
        <template v-slot:label>
            <div class="item-info-container">
                <span v-if="variantId">
                    <strong>ID:</strong> {{ variantId }}
                </span>
                <span v-if="variantName">
                    <strong>Name:</strong> {{ variantName }}
                </span>
                <span v-if="variant.option.value">
                    <strong>{{ variant.option.name | capitalize }}:</strong> {{ variant.option.value }}
                </span>
            </div>
        </template>

        <template v-slot:secondary-button>
            <delete-button :delete-title="variantName" @delete-confirmed="deleteVariant" />
        </template>
        <template v-slot:content>
            <slot />
        </template>
    </foldable-resource-card>
</template>

<script>
import FoldableResourceCard from "@c/foldable-resource-card";
import DeleteButton from "@c/delete-button";
import { mapState } from "vuex";

export default {
    name: "ProductVariant",
    components: {
        FoldableResourceCard,
        DeleteButton
    },
    props: {
        variant: {
            type: Object,
            default() {
                return {
                    name: "{}",
                    slug: "",
                    position: 0,
                    description: "{}",
                    sku: "",
                    shopify_handle: "",
                    is_published: false,
                    slug_pcpartpicker: "",
                    option: {}
                };
            }
        },
        locale: {
            type: String,
            default: "en_US"
        },
        toggleContent: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        ...mapState({
            currentLocale: state => state.Locale.currentLocale.locale
        }),
        variantName() {
            const parsedVariantName = JSON.parse(this.variant.name);
            if (!parsedVariantName) {
                return "";
            }
            return parsedVariantName[this.currentLocale];
        },
        variantId() {
            return this.variant.id || this.variant.products_variants_id;
        },
        variantDescription() {
            const parsedVariantDescription = JSON.parse(
                this.variant.description
            );
            if (!parsedVariantDescription) {
                return "";
            }
            return parsedVariantDescription[this.currentLocale];
        },
        variantPreviewImage() {
            let mainImage = this.variant.files.find((file) => file.field_name == "main");
            if (!mainImage) {
                mainImage = this.variant.files[0];
            }
            return mainImage;
        }
    },
    methods: {
        deleteVariant() {
            axios.delete(`/products-variants/${this.variant.id}`).then(() => {
                this.$notify({
                    text: "Variant was deleted successfully.",
                    type: "success"
                });
                this.$store.dispatch("ProductForm/updateVariantProducts");
            });
        }
    }
};
</script>

<style lang="scss">
.product-variant {
    .image-container {
        display: flex;
        flex-direction: column;

        .product-variant-image {
            width: 72px;
            border-radius: 3px;

            img {
                width: 100%;
            }
        }

        .placeholder-product-variant {
            background-color: rgb(201, 201, 201);
            width: 72px;
            height: 72px;
            border-radius: 3px;
            cursor: pointer;
        }

        .image-caption {
            text-align: center;
            font-size: 12px;
            font-weight: bold;
        }
    }

    .item-info-container {
        display: flex;
        flex-direction: column;
    }
}
</style>
