<template>
    <div class="resource-delete">
        <div class="modal-header">
            <h3>
                <strong>Order merged</strong>
            </h3>
        </div>
        <div class="modal-footer col">
            <div v-for="(input, index) in inputs" :key="'input-' + index" class="row w-100">
                <input
                    v-model="inputData"
                    class="search-input"
                    type="number"
                    :placeholder="input.placeholder"
                >
            </div>
            <div class="w-100">
                <div class="row">
                    <button class="btn btn-block" @click="hide()">
                        Cancel
                    </button>
                    <button class="btn btn-block btn-danger" @click="sendMergue(userId)">
                        Send merge
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "MergeModal",
    props: {
        buttons: {
            type: Array,
            default() {
                return [];
            }
        },
        inputs: {
            type: Array,
            default() {
                return [];
            }
        },
        userId: {
            type: Number,
            default() {
                return 0;
            }
        }
    },
    data() {
        return {
            inputData: ""
        };
    },
    methods: {
        hide() {
            this.$modal.hide("send-merge")
        },
        sendMergue(userId) {
            console.log("HOla", userId)
            axios.post(`/order-merge`, { order_id: this.inputData, user_id: userId })
                .then((response) => {
                    console.log("Data sent successfully", response.data);
                    this.$modal.hide("send-merge");
                })
                .catch((errors) => {
                    this.$notify({
                        text: errors.response.data.errors.message,
                        type: "errors"
                    });
                    this.$modal.hide("send-merge");
                });
        }
    }
}
</script>

<style lang="scss">
.resource-delete {
    .modal-header {
        padding: 30px;
        h3 {
            font-size: 26px;
            text-align: center;
        }
    }
    .modal-footer {
        border-top: 0;
        padding: 30px;
        justify-content: center;
        .col:first-child {
            padding-right: 7px;
            padding-left: 0;
        }
        .col:last-child {
            padding-left: 7px;
            padding-right: 0;
        }
    }

  .search-input {
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }


    .button {
        color: var(--base-color)
    }
}
</style>
