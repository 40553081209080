var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"common-fields"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('language-locale-field')],1)]),_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group form-group-default",class:{ 'border-danger': errors[0] }},[_c('label',{class:{'text-danger': errors[0] }},[_vm._v(" Name "),(errors[0])?_c('span',[_vm._v("(required)")]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"form-control",attrs:{"name":"name","data-vv-validate-on":"blur","type":"text","autofocus":""},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}})])]}}])})],1),_c('div',{staticClass:"col-12 col-lg-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group form-group-default",class:{ 'border-danger': errors[0] }},[_c('label',{staticClass:"form-label",class:{'text-danger': errors[0] }},[_vm._v(" Page Title "),(errors[0])?_c('span',[_vm._v("(required)")]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.pageTitle),expression:"pageTitle",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"name":"page_title","data-vv-validate-on":"blur","type":"text"},domProps:{"value":(_vm.pageTitle)},on:{"input":function($event){if($event.target.composing){ return; }_vm.pageTitle=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})])]}}])})],1),_c('div',{staticClass:"col-12 col-lg-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group form-group-default",class:{ 'border-danger': errors[0] }},[_c('label',{staticClass:"form-label",class:{'text-danger': errors[0] }},[_vm._v(" Slug "),(errors[0])?_c('span',[_vm._v("(required)")]):_vm._e()]),_c('div',{staticClass:"slug-container"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.slug),expression:"slug",modifiers:{"trim":true}}],staticClass:"form-control ",attrs:{"name":"slug","data-vv-validate-on":"blur","type":"text","disabled":_vm.showPublishedToggle == 0},domProps:{"value":(_vm.slug)},on:{"input":function($event){if($event.target.composing){ return; }_vm.slug=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.showPublishedToggle)?_c('button',{staticClass:"generate-slug-btn",on:{"click":function($event){$event.preventDefault();return _vm.generateSlug($event)}}},[_vm._v(" Generate ")]):_vm._e()])])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{class:{'text-danger': errors[0] }},[_vm._v(" Short Description "),(errors[0])?_c('span',[_vm._v("(required)")]):_vm._e()]),_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.shortDescription),expression:"shortDescription",modifiers:{"trim":true}}],staticClass:"form-control",class:{ 'border-danger': errors[0] },attrs:{"name":"short_description","rows":"10"},domProps:{"value":(_vm.shortDescription)},on:{"input":function($event){if($event.target.composing){ return; }_vm.shortDescription=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]}}])})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{class:{'text-danger': errors[0] }},[_vm._v(" Description "),(errors[0])?_c('span',[_vm._v("(required)")]):_vm._e()]),_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.pageDescription),expression:"pageDescription",modifiers:{"trim":true}}],staticClass:"form-control",class:{ 'border-danger': errors[0] },attrs:{"name":"description","rows":"15"},domProps:{"value":(_vm.pageDescription)},on:{"input":function($event){if($event.target.composing){ return; }_vm.pageDescription=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]}}])})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"form-group form-group-default"},[_c('ValidationProvider',{attrs:{"rules":"required"}},[_c('label',{staticClass:"form-label"},[_vm._v(" Position ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.position),expression:"position"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control",attrs:{"name":"position","type":"number","min":"0"},domProps:{"value":(_vm.position)},on:{"input":function($event){if($event.target.composing){ return; }_vm.position=$event.target.value}}})])],1)]),(_vm.showPublishedToggle)?_c('div',{staticClass:"col-12 col-lg-6"},[_c('label',{staticClass:"form-label"},[_vm._v(" Published: ")]),_c('switches',{staticClass:"published-switch",attrs:{"name":"published","color":_vm.isPublished ? 'green' : 'default',"theme":"bulma"},model:{value:(_vm.isPublished),callback:function ($$v) {_vm.isPublished=$$v},expression:"isPublished"}})],1):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }