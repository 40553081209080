<template>
    <div class="board-container">
        <div>
            <h1 class="section-title">
                QUICK LINKS
            </h1>
            <div class="browser">
                <router-link to="/browse/products" class="browser__box available">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                        />
                    </svg>
                    <h3 class="browser__title">
                        Browse Inventory
                    </h3>
                </router-link>
                <div v-if="showUsersList">
                    <router-link to="/users/users-list" class="browser__box available">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                            />
                        </svg>
                        <h3 class="browser__title">
                            Manage Users
                        </h3>
                    </router-link>
                </div>
                <div class="browser__box disabled">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        />
                    </svg>
                    <h3 class="browser__title">
                        Add product
                    </h3>
                </div>
                <div class="browser__box disabled">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                        />
                    </svg>
                    <h3 class="browser__title">
                        Inventory Sync
                    </h3>
                </div>
            </div>
        </div>
        <div>
            <h1 class="section-title">
                TAKE ACTION
            </h1>
            <div class="actions">
                <div class="actions__inventory">
                    <span class="action-number-inventary">50</span>
                    <span>Inventory with Low Stock</span>
                </div>
                <div class="actions__information">
                    <div class="actions__box--container">
                        <div class="actions__box">
                            <span class="action-title">Inventory in store</span>
                            <span class="action-number">0</span>
                        </div>
                        <div class="actions__box">
                            <span class="action-title">Inventory Missing Sync</span>
                            <span class="action-number">0</span>
                        </div>
                    </div>
                    <div class="actions__box--container">
                        <div class="actions__box">
                            <span class="action-title">Inventory in configurator</span>
                            <span class="action-number">0</span>
                        </div>
                        <div class="actions__box">
                            <span class="action-title">Inventory Missing Sync</span>
                            <span class="action-number">0</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "Dashboard",
    computed: {
        ...mapGetters({
            userRoles: "User/userRoles"
        }),
        showUsersList() {
            return this.userRoles.includes("Admins") || this.userRoles.includes("CustomerSupport");
        }
    }
}
</script>

<style lang="scss" scoped>
    .board-container {
        max-width: 86.5rem;
        margin: auto;
    }

    .section-title {
        font-size: 1.125rem;
        letter-spacing: 0;
        font-weight: 300;
        color: #000;
        margin: 1rem;
    }
    
    .icon {
        width: 3rem;
        color: #fff;
    }

    .browser {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        
        &__title {
            font-size: 1.9375rem;
            color: #fff;
            font-weight: 300;
            margin: 0.625rem;
        }

        &__box {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 27.16625rem;
            height: 5rem;
            padding: 10px 20px;
            border-radius: 0.3125rem;
            margin-bottom: 0.5rem;
            cursor: pointer;
            text-decoration: none;
        }
    }

    .actions {
        display: flex;
        justify-content: center;
        align-items: center;

        &__inventory {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 27.79125rem;
            height: 10.5rem;
            background: #c9c9c9;
            color: white;
            border-radius: 0.3125rem;
            margin: 0.3rem;
            padding: 1rem;

            .action-number-inventary {
                font-size: 5.01875rem;
            }
        }

        &__information {
            display: flex;
            flex-direction: column;
            
        }

        &__box--container {
            display: flex;
        }

        &__box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1.25rem;
            width: 27.16625rem;
            height: 5rem;
            border-radius: 0.3125rem;
            border: 1px solid rgba(0,0,0,.125);
            margin: 0.3rem;
            background: #fff;

            .action-number,
            .action-title {
                color: #0088d7;
            }

            .action-title {
                font-size: 1.5625rem;
                font-weight: lighter;
            }

            .action-number {
                font-size: 2.5rem;
                font-weight: bold;
            }
        }
    }

    .available {
        background-color: #0088d7;
    }

    .disabled {
        background-color: #cbcccc;
        cursor: not-allowed;
    }
</style>
