import { render, staticRenderFns } from "./variant-form.vue?vue&type=template&id=2c52bef7&scoped=true&"
import script from "./variant-form.vue?vue&type=script&lang=js&"
export * from "./variant-form.vue?vue&type=script&lang=js&"
import style0 from "./variant-form.vue?vue&type=style&index=0&id=2c52bef7&lang=scss&scoped=true&"
import style1 from "@/assets/scss/resource.scss?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c52bef7",
  null
  
)

export default component.exports