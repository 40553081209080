<template>
    <div class="image-list row">
        <div v-for="image in images" :key="image.id" class="col-12 col-lg-6">
            <image-preview
                :image="image"
                :image-types="imageTypes"
                :coolers-list="coolersList"
                @delete-clicked="$emit('image-delete', image.id)"
            />
        </div>
        <div class="new-image-container col-12 col-lg-6">
            <add-new-image
                :image-types="imageTypes"
                :coolers-list="coolersList"
                @cooler-changed="(data) => cooler = data"
                @new-clicked="newImageClicked"
                @type-changed="(type) => imageType = type.slug"
            />
        </div>
    </div>
</template>

<script>

import AddNewImage from "./add-new-image";
import ImagePreview from "./preview";
import { mapState } from "vuex";

export default {
    name: "VariantImageList",
    components: {
        AddNewImage,
        ImagePreview
    },
    props: {
        images: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            cooler: null,
            imageFile: {},
            imageType: "",
            coolersList: []
        }
    },
    computed: {
        ...mapState({
            variantFormData: state => state.VariantForm.formData,
            imageTypes: state => state.ProductForm.media_types
        })
    },
    created() {
        this.getCoolers();
    },
    methods: {
        async newImageClicked(event) {
            const image = event.target.files[0];
            this.$store.commit("Application/SET_IS_LOADING", true);

            try {
                const formData = new FormData();
                formData.append(this.imageType, image);
                formData.append("name", image.name);

                if (this.cooler) {
                    formData.append("cooler_product", JSON.stringify({
                        products_id: this.cooler.products_id,
                        variants_id: this.cooler.variants_id
                    }));
                }

                await axios({
                    method: "POST",
                    url: "/filesystem",
                    data: formData
                }).then(({ data: imageFile }) => {
                    this.imageFile = imageFile[0];
                    this.$emit("image-uploaded", { image: this.imageFile, field_name: this.imageType });
                })
            } catch (e) {
                console.error(e);
            } finally {
                this.$store.commit("Application/SET_IS_LOADING", false);
            }
        },
        getCoolers() {
            axios({
                url: "/products-cooling?relationships=variants&limit=100"
            }).then(({ data: coolers }) => {
                this.coolersList = coolers.map((cooler) => {
                    if (cooler.variants.length) {
                        return {
                            name: cooler.name,
                            products_id: Number(cooler.id),
                            variants_id: Number(cooler.variants[0].id)
                        }
                    }

                    return cooler;
                });
            });
        }
    }
}
</script>

<style lang="scss">
.image-list {
    padding: 12px;
    width: 100%;

    .new-image-container {
        width: 150px;
    }
}
</style>
