<template>
    <div class="row product-variant-list">
        <div v-if="variants.length" class="col-12">
            <div
                v-for="variant in variants"
                :key="variant.id"
                class="product-variant-container "
                :class="{'product-variant-edit-box': currentVariantId == variant.id }"
            >
                <product-variant-item
                    :variant="variant"
                    :locale="locale"
                    :toggle-content="shouldToggleForm"
                >
                    <variant-form :variant="variant" @cancel="$_toggleGeneralFormFold" />
                </product-variant-item>
            </div>
        </div>

        <div class="product-variant-container col-12">
            <new-product-variant
                :product-id="productId"
                :toggle-content="shouldToggleForm"
            >
                <variant-form @cancel="$_toggleGeneralFormFold" />
            </new-product-variant>
        </div>
    </div>
</template>

<script>
import ProductVariantItem from "@c/variants/variant";
import NewProductVariant from "@c/variants/new-variant";
import VariantForm from "./variant-form";
import VariantMixin from "@m/variantMixin"

export default {
    name: "ProductVariantList",
    components: {
        ProductVariantItem,
        NewProductVariant,
        VariantForm
    },
    mixins: [VariantMixin],
    props: {
        productId: {
            type: [Number, String],
            default: ""
        },
        locale: {
            type: String,
            required: true
        },
        variants: {
            type: [Array, Object],
            required: true
        }
    },
    data() {
        return {
            currentVariantId: "",
            showNewVariantForm: false
        }
    }
}
</script>

<style lang="scss" scoped>
.product-variant-list {
    .product-variant-edit-box {
        background-color: #f1f1f1;

        .product-variant {
            padding-top: 10px;
        }
    }

    .product-variant-container {
        margin-bottom: 20px;
    }

}
</style>
