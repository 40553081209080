<template>
    <div class="row">
        <div class="col-6">
            <h3>Category</h3>
            <language-locale-field />
            <div :class="{ 'border-danger': errors.has('name') }" class="form-group form-group-default">
                <label :class="{'text-danger': errors.has('name') }">
                    Name
                    <span v-if="errors.has('name')">(required)</span>
                </label>
                <input
                    v-model="name"
                    v-validate="'required'"
                    rule
                    name="name"
                    data-vv-validate-on="blur"
                    class="form-control"
                    type="text"
                >
            </div>
            <div :class="{ 'border-danger': errors.has('slug') }" class="form-group form-group-default">
                <label :class="{'text-danger': errors.has('slug') }">
                    Slug
                    <span v-if="errors.has('slug')">(required)</span>
                </label>
                <input
                    v-model="formData.slug"
                    v-validate="'required'"
                    rule
                    name="slug"
                    data-vv-validate-on="blur"
                    class="form-control"
                    type="text"
                >
            </div>
            <div :class="{ 'border-danger': errors.has('menuName') }" class="form-group form-group-default">
                <label :class="{'text-danger': errors.has('menuName') }">
                    Menu Name
                    <span v-if="errors.has('menuName')">(required)</span>
                </label>
                <input
                    v-model="menuName"
                    v-validate="'required'"
                    rule
                    name="menuName"
                    data-vv-validate-on="blur"
                    class="form-control"
                    type="text"
                >
            </div>
            <div :class="{ 'border-danger': errors.has('pageTitle') }" class="form-group form-group-default">
                <label :class="{'text-danger': errors.has('pageTitle') }">
                    Page Title
                    <span v-if="errors.has('pageTitle')">(required)</span>
                </label>
                <input
                    v-model="pageTitle"
                    v-validate="'required'"
                    rule
                    name="pageTitle"
                    data-vv-validate-on="blur"
                    class="form-control"
                    type="text"
                >
            </div>
            <div :class="{ 'border-danger': errors.has('position') }" class="form-group form-group-default">
                <label :class="{'text-danger': errors.has('position') }">
                    Position
                    <span v-if="errors.has('position')">(required)</span>
                </label>
                <input
                    v-model="formData.position"
                    v-validate="'required'"
                    rule
                    name="slug"
                    data-vv-validate-on="blur"
                    class="form-control"
                    type="text"
                >
            </div>
            <div :class="{ 'border-danger': errors.has('parentId') }">
                <label :class="{'text-danger': errors.has('parentId') }">
                    Parent id
                    <span v-if="errors.has('parentId')">(required)</span>
                </label>
                <multiselect
                    v-model="parentId"
                    :options="categories"
                    :allow-empty="false"
                    deselect-label="Can't remove this value"
                    placeholder="Pick a value"
                    track-by="name"
                    label="name"
                />
            </div>
            <div class="row switch">
                <div class="col-12">
                    <label class="form-label switch-label">Show in menu:</label>
                    <switches
                        v-model="formData.show_in_menu"
                        name="published"
                        :color="formData.show_in_menu ? 'green' : 'default'"
                        class="published-switch"
                        theme="bulma"
                    />
                </div>
                <div class="col-12">
                    <label class="form-label switch-label">Published:</label>
                    <switches
                        v-model="formData.is_published"
                        name="published"
                        :color="formData.is_published ? 'green' : 'default'"
                        class="published-switch"
                        theme="bulma"
                    />
                </div>
            </div>
            <div class="form-group form-group-default">
                <label>
                    Product Service
                </label>
                <input
                    v-model="formData.product_service_id"
                    rule
                    name="productService"
                    data-vv-validate-on="blur"
                    class="form-control"
                    type="text"
                >
            </div>
            <button class="btn btn-success btn-lg btn-block" type="button" @click="updateOrCreate">
                {{ btnMsg }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { composeResources } from "@/utils/helpers";
import _pick from "lodash/pick";
import { isJson } from "@/utils/helpers";
import LanguageLocaleField from "@c/language-locale-field";
import Switches from "vue-switches";

export default {
    name: "CategoriesForm",
    components: {
        LanguageLocaleField,
        Switches
    },
    data() {
        return {
            formData: {
                name: "",
                menu_name: "",
                slug: "",
                position: "",
                parent_id: "",
                show_in_menu: "",
                page_title: "",
                is_published: "",
                product_service_id: ""
            },
            categories: []
        }
    },
    computed: {
        ...mapState({
            resources: state => composeResources(state.Application.resources),
            currentLocale: state => state.Locale.currentLocale.locale            
        }),
        resource() {
            return this.resources.find(resource => resource.slug == this.$route.params.resource);
        },
        resourceSlug() {
            const { slug } = this.resource;
            return slug;
        },
        ruleId() {
            return this.$route.params.id;
        },
        btnMsg() {
            return this.ruleId ? "Update" : "Create";
        },
        parentId: {
            get() {
                const parent = this.categories.filter(category => category.id === this.formData.parent_id);
                return parent ? parent : ""
            },
            set(value) {
                this.formData.parent_id = value.id;
            }
        },
        name: {
            get() {
                return isJson(this.formData.name) ? 
                    this.checkCurrentLocale(this.formData.name) : this.formData.name
            },
            set(value) {
                const newMessage = {
                    [this.currentLocale]: value
                }

                const parsedName = isJson(this.formData.name) ? JSON.parse(this.formData.name) : "";

                this.formData.name = JSON.stringify(Object.assign({}, parsedName, newMessage));
            }
        },
        productService: {
            get() {
                return isJson(this.formData.product_service_id) ?
                    this.checkCurrentLocale(this.formData.product_service_id) : this.formData.product_service_id
            },
            set(value) {
                const newMessage = {
                    [this.currentLocale]: value
                }

                const parsedName = isJson(this.formData.product_service_id) ? JSON.parse(this.formData.product_service_id) : "";

                this.formData.product_service_id = JSON.stringify(Object.assign({}, parsedName, newMessage));
            }
        },
        menuName: {
            get() {
                return isJson(this.formData.menu_name) ? 
                    this.checkCurrentLocale(this.formData.menu_name) : this.formData.menu_name
            },
            set(value) {
                const newMessage = {
                    [this.currentLocale]: value
                }

                const parsedName = isJson(this.formData.menu_name) ? JSON.parse(this.formData.menu_name) : "";

                this.formData.menu_name = JSON.stringify(Object.assign({}, parsedName, newMessage));
            }
        },
        pageTitle: {
            get() {
                return isJson(this.formData.page_title) ? 
                    this.checkCurrentLocale(this.formData.page_title) : this.formData.page_title
            },
            set(value) {
                const newMessage = {
                    [this.currentLocale]: value
                }

                const parsedName = isJson(this.formData.page_title) ? JSON.parse(this.formData.page_title) : "";

                this.formData.page_title = JSON.stringify(Object.assign({}, parsedName, newMessage));
            }
        }
    },
    mounted() {
        this.categoriesForm();

        if (this.ruleId) {
            this.category(this.ruleId);
        }
    },
    methods: {
        async categoriesForm() {
            const { data: Categories } = await axios("/categories");
            this.categories = Categories;
        },
        async category(id) {
            const { data: Category } = await axios.get(`/${ this.resourceSlug }/${ id }`);
            const formatedCategory = [
                _pick(Category, Object.keys(this.formData)),
                {
                    show_in_menu: Boolean(Number(Category.show_in_menu)),
                    is_published: Boolean(Number(Category.is_published))
                }
            ]

            const mergedFormData = Object.assign(
                {}, 
                this.formData,
                ...formatedCategory
            );

            this.formData = mergedFormData;
        },
        checkCurrentLocale(name) {
            return JSON.parse(name)[this.currentLocale] ? 
                JSON.parse(name)[this.currentLocale] : JSON.parse(name)["en_US"];
        },
        async updateOrCreate() {
            const formatedCategory = Object.assign({}, this.formData, {
                show_in_menu: String(Number(this.formData.show_in_menu)),
                is_published: String(Number(this.formData.is_published))
            })

            try {
                await axios({
                    url: this.ruleId ? `/categories/${ this.ruleId }` : `/categories`,
                    method: this.ruleId ? "PUT" : "POST",
                    data: formatedCategory
                });

                this.$notify({
                    title: "Updated",
                    text: this.ruleId ? "Category was updated successfully" : "Category was created successfully",
                    type: "success"
                });               
            } catch (error) {
                this.onError(error);
            }
        },
        async onError(error) {
            const { data: { errors } } = error.response;
            this.$notify({
                title: errors.type,
                text: errors.message,
                type: "error"
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.switch {
    width: 300px;
    display: flex;
    justify-content: space-between;

    .published-switch {
        margin: -2px 8px;
    }
}
</style>
