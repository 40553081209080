<template>
    <div>
        <div class="row">
            <div class="col-12 col-md-6">
                <div :class="{ 'border-danger': errors.has('type') }">
                    <label :class="{'text-danger': errors.has('type') }">
                        Fields Type
                        <span v-if="errors.has('type')">(required)</span>
                    </label>
                    <multiselect
                        v-model="setType"
                        class="mb-2"
                        :options="fieldsTypes"
                        :allow-empty="false"
                        deselect-label="Can't remove this value"
                        placeholder="Pick a value"
                        track-by="name"
                        label="name"
                    />
                </div>

                <div :class="{ 'border-danger': errors.has('title') }" class="form-group form-group-default">
                    <label :class="{'text-danger': errors.has('title') }">
                        Title
                        <span v-if="errors.has('title')">(required)</span>
                    </label>
                    <input
                        v-model="formData.title"
                        v-validate="'required'"
                        name="title"
                        data-vv-validate-on="blur"
                        class="form-control"
                        type="text"
                    >
                </div>

                <div :class="{ 'border-danger': errors.has('slug') }" class="form-group form-group-default">
                    <label :class="{'text-danger': errors.has('slug') }">
                        Slug
                        <span v-if="errors.has('slug')">(required)</span>
                    </label>
                    <input
                        v-model="formData.slug"
                        v-validate="'required'"
                        name="slug"
                        data-vv-validate-on="blur"
                        class="form-control"
                        type="text"
                    >
                </div>

                <div :class="{ 'border-danger': errors.has('unit') }" class="form-group form-group-default">
                    <label :class="{'text-danger': errors.has('unit') }">
                        Unit
                        <span v-if="errors.has('unit')">(required)</span>
                    </label>
                    <input
                        v-model="formData.unit"
                        v-validate="'required'"
                        name="unit"
                        data-vv-validate-on="blur"
                        class="form-control"
                        type="text"
                    >
                </div>
             
                <div :class="{ 'border-danger': errors.has('description') }" class="form-group form-group-default">
                    <label :class="{'text-danger': errors.has('description') }">
                        Description
                        <span v-if="errors.has('description')">(required)</span>
                    </label>
                    <textarea
                        v-model="formData.description"
                        v-validate="'required'"
                        name="description"
                        data-vv-validate-on="blur"
                        class="form-control"
                        type="text"
                    />
                </div>
            </div>
            <div class="col-12 switch">
                <label class="form-label switch-label">Has values:</label>
                <switches
                    v-model="formData.has_values"
                    name="published"
                    :color="formData.has_values ? 'green' : 'default'"
                    class="published-switch"
                    theme="bulma"
                />
            </div>
            <div class="col-12 col-md-6">
                <button
                    class="btn btn-success btn-lg btn-block"
                    type="button"
                    @click="updateOrCreate"
                >
                    {{ btnMsg }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { composeResources } from "@/utils/helpers";
import Switches from "vue-switches";

export default {
    name: "AttibutesForm",
    components: { Switches },
    data() {
        return {
            fieldsTypes: [],
            formData: {
                fields_types_id: "",
                title: "",
                slug: "",
                has_values: false,
                default_value: "",
                description: "",
                unit: ""
            }
        }
    },
    computed: {
        ...mapState({
            resources: state => composeResources(state.Application.resources)
        }),
        resource() {
            return this.resources.find(resource => resource.slug == this.$route.params.resource);
        },
        resourceSlug() {
            const { slug } = this.resource;
            return slug;
        },
        attributeId() {
            return this.$route.params.id;
        },
        btnMsg() {
            return this.attributeId ? "Update" : "Create";
        },
        setType: {
            get() {
                const { fields_types_id: typeId } = this.formData;
                return this.fieldsTypes.find((({ id }) => id === typeId));
            },
            set({ id }) {
                this.formData.fields_types_id = id;
            }
        }
    },
    created() {
        this.getFieldsTypes();

        if (this.attributeId) {
            this.attribute(this.attributeId);
        }
    },
    methods: {
        async getFieldsTypes() {
            const { data: FieldTypes } = await axios("/fields-types");
            this.fieldsTypes = FieldTypes;
        },
        async attribute(id) {
            const { data: AttributeData } = await axios.get(`/${ this.resourceSlug }/${ id }`);
        
            const formatedData = { 
                ...AttributeData, 
                has_values: Boolean(Number(AttributeData.has_values)) 
            }

            const mergedFormData = Object.assign(
                {}, 
                this.formData,
                formatedData
            );
            
            this.formData = mergedFormData;
        },
        async updateOrCreate() {
            const formatedAtribute = Object.assign({}, this.formData, {
                has_values: String(Number(this.formData.has_values))
            });

            try {
                await axios({
                    url: this.attributeId ? `/attributes/${ this.attributeId }` : `/attributes`,
                    method: this.attributeId ? "PUT" : "POST",
                    data: formatedAtribute
                });

                this.$notify({
                    title: "Updated",
                    text: this.attributeId ? "Attribute was updated successfully" : "Attribute was created successfully",
                    type: "success"
                });               
            } catch (error) {
                this.onError(error);
            }
        },
        async onError(error) {
            const { data: { errors } } = error.response;
            this.$notify({
                title: errors.type,
                text: errors.message,
                type: "error"
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.switch {
    width: 300px;
    display: flex;
    align-items: baseline;
    .published-switch {
        margin: -2px 8px;
    }
}
</style>
