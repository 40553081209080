<template>
    <div class="resource-card">
        <div class="card">
            <slot name="image" />
            <span class="label">
                <slot name="label" />
            </span>
            <div class="button-group">
                <slot name="primary-button" />
                <slot name="secondary-button" />
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: "ResourceCard"
}
</script>

<style lang="scss">

.resource-card {
    .card {
        display: flex;
        border: 0.5px solid rgba(184, 184, 184, 0.849);
        background-color: #F7F7F7;
        padding: 10px;
        justify-content: space-between;
        width: 100%;
        flex-direction: row;

        .label {
            display: flex;
            align-items: center;
        }

        .button-group {
            display: flex;
            align-items: center;

            button {
                margin-right: 10px;
                height: 40px;
                width: 70px;
                background: transparent;
                border: 1px solid rgb(110, 110, 110);
                border-radius: 3px;
                font-weight: 500;
                cursor: pointer;

                &:hover {
                    background-color: rgb(201, 201, 201);
                    border: 0;
                    color: white;
                }
            }

            button:focus {
                outline-color: rgba(121, 121, 121, 0.884);
            }
        }
    }
}
</style>
