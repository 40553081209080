import cloneDeep from "lodash/cloneDeep";
import store from "@/store";

const getDefaultStates = () => {
    return {
        formData: {
            product_id: 0,
            is_published_for_amd: false,
            is_published_for_intel: false,
            is_in_last_chance_modal: false,
            is_hidden: false,
            is_published: false,
            max_quantity: false,
            builder_category_id: 0,
            subcategory : ""
        },
        variants: [],
        attributes: [],
        selectOptions: [
            { value: 0, label: "Keyboards" },
            { value: 1, label: "Mouse" },
            { value: 2, label: "Audio" },
            { value: 3, label: "Accessories" },
            { value: 4, label: "Monitors" },
            { value: 5, label: "Lighting" },
            { value: 6, label: "Capture Card" }
        ]
    }
};

const state = getDefaultStates();

const mutations = {
    SET_FORM_DATA(state, data) {
        state.formData = data;
    },
    SET_OPTIONS(state, option) {
        state.formData = {
            ...state.formData,
            subcategory : option
        };
    },
    SET_TYPE(state, type) {
        state.formData = {
            ...state.formData,
            subcategory : type
        };
    },
    SET_IS_PUBLISHED(state, isPublished) {
        state.formData = {
            ...state.formData,
            is_published: isPublished
        };
    },
    SET_IS_PUBLISHED_AMD(state, isPublishedAmd) {
        state.formData = {
            ...state.formData,
            is_published_for_amd: isPublishedAmd
        };
    },
    SET_IS_PUBLISHED_INTEL(state, isPublishedIntel) {
        state.formData = {
            ...state.formData,
            is_published_for_intel: isPublishedIntel
        };
    },
    SET_IS_IN_LAST_CHANCE_MODAL(state, isInLastChanceModal) {
        state.formData = {
            ...state.formData,
            is_in_last_chance_modal: isInLastChanceModal
        };
    },
    SET_IS_HIDDEN(state, isHidden) {
        state.formData = {
            ...state.formData,
            is_hidden: isHidden
        };
    },
    SET_VARIANTS(state, variants) {
        state.variants = variants;
    },
    SET_ATTRIBUTES(state, attributes) {
        state.attributes = attributes;
    },
    SET_QUANTITY(state, maxQuantity) {
        state.formData = {
            ...state.formData,
            max_quantity: maxQuantity
        };
    },
    SET_BLD_CATEGORY(state, bldCategory) {
        state.formData = {
            ...state.formData,
            builder_category_id: bldCategory
        };
    },
    SET_BLD_SUB_CATEGORY(state, bldSubCategory) {
        state.formData = {
            ...state.formData,
            subcategory: bldSubCategory
        };
    },
    CLEAN(state) {
        Object.assign(state, getDefaultStates());
    }
};

const actions = {
    updateVariants({ commit, state }) {
        const defaultRegionId = store.state.Region.currentRegionId;

        return axios.get(`/builder/regions/${defaultRegionId}/products/${state.formData.product_id}/variants`)
            .then(({ data: variants }) => {
                let clonedVariants = cloneDeep(state.variants);
                clonedVariants = variants;
                commit("SET_VARIANTS", clonedVariants);
            });
    },
    updateAttributes({ commit, state }) {
        const { product_id: productId } = state.formData;
        return axios(`/products/${productId}/attributes`).then(({ data }) => {
            commit("SET_ATTRIBUTES", data);
        });
    },
    async fetchData({ commit, dispatch }, productId) {
        const defaultRegionId = store.state.Region.currentRegionId;

        store.commit("Application/SET_IS_LOADING", true);

        const { data: product } = await axios.get(`/builder/regions/${defaultRegionId}/products/${productId}`)
        const productFormData = {
            ...product,
            subcategory: product.builder_category
        };
        commit("SET_FORM_DATA", productFormData);

        await dispatch("updateVariants");
        await dispatch("updateAttributes");
        dispatch("parseFormData");

        store.commit("Application/SET_IS_LOADING", false);
    },
    parseFormData({ commit, state }) {
        const parsedData = {
            ...state.formData,
            is_published: Boolean(Number(state.formData.is_published)),
            is_published_for_amd: Boolean(Number(state.formData.is_published_for_amd)),
            is_published_for_intel: Boolean(Number(state.formData.is_published_for_intel)),
            is_in_last_chance_modal: Boolean(Number(state.formData.is_in_last_chance_modal)),
            is_hidden: Boolean(Number(state.formData.is_hidden))
        };
        commit("SET_FORM_DATA", parsedData);
    },
    clean({ commit }) {
        commit("CLEAN");
    }
};

const getters = {
    getSelectOptions(state) {
        return state.selectOptions;
    },
    variantProductLength(state) {
        return state.formData.product_variants.length;
    },
    preparedFormData(state) {
        return {
            ...state.formData,
            is_published: Number(state.formData.is_published),
            is_published_for_amd: Number(state.formData.is_published_for_amd),
            is_published_for_intel: Number(state.formData.is_published_for_intel),
            is_in_last_chance_modal: Number(Number(state.formData.is_in_last_chance_modal)),
            is_hidden: Number(state.formData.is_hidden)
        };
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
