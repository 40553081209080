export default {
    "products-cases": 4,
    "products-cpus": 120,
    "products-cooling": 37,
    "products-motherboards": 70,
    "products-gpus": 112,
    "products-power-supplies": 76,
    "products-ram": 115,
    "products-storage": 117,
    "products-rgbs": 124,
    "products-extras": 113,
    "products-peripherals": 121,
    "products-monitors": 123,
    "products-softwares": 111,
    "products-services": 122,
    "products-psus": 116,
    "products-fans": 44,
    "products-prebuilds": 131,
    "products-creator--pc-series": 137,
    "products-h1-mini-pc-series": 136,
    "products-streaming-pc-series": 134,
    "products-starter-pc": 132,
    "products-refurbished-components": 139,
    "products-refurbished-builds": 141,
    "products-kb-keyboard-base" : 160,
    "products-kb-key-caps" : 161,
    "products-kb-key-accents" : 162,
    "products-kb-switches" : 163,
    "products-kb-cables" : 164,
    "products-kb-mouse" : 165,
    "products-kb-services" : 166,
    "products-kb-keyboard-extra" : 167,
    "products-warranties" : 168
}
