<template>
    <form v-if="formData.id || !isEditing" class="resource-form" @submit.prevent>
        <div class="row">
            <div class="col">
                <h3 class="title">
                    Product Information
                </h3>
            </div>
        </div>

        <foldable-resource-card class="mb-5" :toggle-content="shouldToggleForm">
            <template v-if="productImages.length" v-slot:image>
                <img :src="productImages[0].url">
            </template>
            <template v-slot:label>
                <div class="item-info-container">
                    <span><strong>Name:</strong> {{ name }}</span>
                    <span><strong>Short Description:</strong> {{ formData.short_description[currentLocale] }}</span>
                </div>
            </template>
            <template v-slot:content>
                <general-fields ref="generalFields" />
                <h4>
                    Images
                </h4>
                <product-image-list :images="productImages" @image-uploaded="onImageUploaded" />

                <h4>
                    Manuals
                </h4>
                <product-manuals :manuals="productManuals" @manual-uploaded="onManualUploaded" />

                <form-actions
                    :is-loading="false"
                    @save="onSaveForm"
                    @cancel="onCancelForm"
                >
                    <template v-slot:submit-label>
                        Save Product
                    </template>
                </form-actions>
            </template>
            <template v-if="isEditing" v-slot:secondary-button>
                <delete-button :delete-title="formData.name" @delete-confirmed="deleteProduct" />
            </template>
        </foldable-resource-card>

        <div v-if="formData.id">
            <div class="row">
                <div class="col">
                    <h3 class="title">
                        Variants
                    </h3>
                </div>
            </div>
            <product-variant-list
                :product-id="formData.id"
                :locale="currentLocale"
                :variants="productVariants"
            />
        </div>
    </form>
</template>

<script>

import GeneralFields from "./general-fields.vue";
import FormActions from "@c/form-actions.vue";
import ProductVariantList from "./variants/";
import categoriesIDS from "../categoriesIds";
import { mapState } from "vuex";
import ProductImageList from "./product-image-list";
import cloneDeep from "lodash/cloneDeep";
import FoldableResourceCard from "@c/foldable-resource-card";
import ProductManuals from "./product-manuals";
import formsMixin from "@m/formsMixin.js";
import DeleteButton from "@c/delete-button";
import { isJson } from "@/utils/helpers";

export default {
    name: "CasesForm",
    components: {
        GeneralFields,
        FormActions,
        ProductVariantList,
        ProductImageList,
        FoldableResourceCard,
        ProductManuals,
        DeleteButton
    },
    mixins: [formsMixin],
    data() {
        return {
            shouldToggleForm: false
        }
    },
    computed: {
        ...mapState({
            currentLocale: state => state.Locale.currentLocale.locale,
            formData: state => state.ProductForm.formData,
            productVariants: state => state.ProductForm.product_variants,
            imageTypes: state => state.ProductForm.media_types
        }),
        name() {
            return isJson(this.formData.name) ? this.checkCurrentLocale(this.formData.name) : this.formData.name;

        },
        resource() {
            return this.resources.find(resource => resource.slug == this.$route.params.resource);
        },
        resourceEndpoint() {
            return this.resource.slug.split("-")[0];
        },
        productManuals() {
            return this.formData.files.filter((file) => file.field_name == "manual");
        },
        isEditing() {
            return Boolean(this.$route.params.id);
        },
        productImages() {
            const imageTypeSlugs = this.imageTypes.map((image) => image.slug);
            const images = this.formData.files.filter((file) => {
                return imageTypeSlugs.includes(file.field_name) && file.field_name != "manual";
            });
            return images;
        },
        fetchUrl() {
            const productId = this.$route.params.id;
            return `/${this.resourceEndpoint}/${productId}?relationships=files`;
        },
        submitUrl() {
            return this.isEditing ? `/${this.resourceEndpoint}/${this.formData.id}` : `/${this.resourceEndpoint}`;
        },
        submitMethod() {
            return this.isEditing ? "PUT" : "POST";
        }
    },
    watch: {
        resource: {
            handler() {
                if (this.isEditing) {
                    this.$_fetchResource((formData) => {
                        this.$store.commit("ProductForm/SET_FORM_DATA", formData);
                        this.$store.dispatch("ProductForm/parseData");
                        this.$store.dispatch("ProductForm/updateVariantProducts");
                    });
                }
            },
            immediate: true
        }
    },
    created() {
        const categoryId = categoriesIDS[this.$route.params.resource];

        this.$store.commit("ProductForm/SET_SLUG", this.$route.params.resource);
        this.$store.commit("ProductForm/SET_CATEGORIES_ID", categoryId);
        this.$store.commit("ProductForm/SET_BASE_CATEGORY_ID", categoryId);
        this.$store.dispatch("ProductForm/addMediaTypes");
    },
    destroyed() {
        this.$store.dispatch("ProductForm/clean");
    },
    methods: {
        checkCurrentLocale(name) {
            return JSON.parse(name)[this.currentLocale] ? 
                JSON.parse(name)[this.currentLocale] : JSON.parse(name)["en_US"];
        },
        stringifyVariantFields(data) {
            const description = JSON.stringify(data.description);
            const pageTitle = JSON.stringify(data.page_title);
            const regionProducts = JSON.stringify(data.regions_products);
            const shortDescription = JSON.stringify(data.short_description);

            return {
                ...data,
                description,
                page_title: pageTitle,
                regions_products: regionProducts,
                short_description: shortDescription
            }
        },
        async onSaveForm() {
            const isValid = await this.$refs.generalFields.isValid();
            
            if (!isValid) {
                return;
            }

            this.$_submitResource(this.stringifyVariantFields, this.onSubmit);
        },
        async onSubmit(product) {
            this.$notify({
                text: "Product form was saved successfully.",
                type: "success"
            });

            if (!this.isEditing) {
                this.$router.push({
                    name: "edit-resource",
                    params: {
                        resource: this.$route.params.resource,
                        id: product.id
                    }
                });
            }

            this.toggleGeneralFormFold();
        },
        onCancelForm() {
            this.toggleGeneralFormFold();
        },
        toggleGeneralFormFold() {
            this.shouldToggleForm = !this.shouldToggleForm;
        },
        onImageUploaded(file) {
            const newImage = {
                field_name: file.field_name,
                url: file.image.url,
                filesystem_id: file.image.id
            }

            const images = cloneDeep(this.formData.files);
            images.push(newImage);
            this.$store.commit("ProductForm/SET_FILES", images);
        },
        onManualUploaded(file) {
            const newManual = {
                field_name: file.field_name,
                url: file.manual.url,
                filesystem_id: file.manual.id,
                name: file.manual.name
            }

            const manuals = cloneDeep(this.formData.files);
            manuals.push(newManual);
            this.$store.commit("ProductForm/SET_FILES", manuals);
        },
        deleteProduct() {
            axios.delete(`/products/${this.formData.id}`)
                .then(() => {
                    this.$router.replace({ name: "browse", params: { resource: this.$route.params.resource } });
                });
        }
    }
}
</script>

<style>
.item-info-container {
    display: flex;
    flex-direction: column;
}
</style>


<style lang="scss" src="@/assets/scss/resource.scss" />
