export default {
    methods: {
        $_prepareAttributes(attributes) {
            const preparedBldAttributes = this.attributes.map((attribute) => {
                return {
                    ...attribute,
                    value: attributes.values[attribute.slug]
                }
            })
            return { attributes: preparedBldAttributes };
        }
    }
}
