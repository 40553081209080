<template>
    <div class="row product-variant-list">
        <div class="col-12">
            <div
                v-for="variant in variants"
                :key="variant.id"
                class="product-variant-container"
                :class="{ 'product-variant-edit-box': currentVariantId == variant.id }"
            >
                <store-variant-item
                    :variant="variant"
                    :locale="locale"
                    :toggle-content="shouldToggleForm"
                >
                    <store-variant-form :variant="variant" @cancel="$_toggleGeneralFormFold" />
                </store-variant-item>
            </div>
        </div>
    </div>
</template>

<script>

import StoreVariantItem from "@c/variants/variant";
import StoreVariantForm from "./variant-form";
import VariantMixin from "@m/variantMixin"

export default {
    name: "ProductVariantList",
    components: {
        StoreVariantItem,
        StoreVariantForm
    },
    mixins: [VariantMixin],
    props: {
        locale: {
            type: String,
            required: true
        },
        variants: {
            type: [Array, Object],
            required: true
        }
    },
    data() {
        return {
            currentVariantId: "",
            showNewVariantForm: false
        }
    }
}
</script>

<style lang="scss" scoped>
.product-variant-list {
    .product-variant-edit-box {
        background-color: #f1f1f1;

        .product-variant {
            padding-top: 10px;
        }
    }

    .product-variant-container {
        margin-bottom: 20px;
    }

}
</style>
