<template>
    <div class="form-group-multiselect">
        <label class="form-label"> Language </label>
        <multiselect
            v-model="selectedLanguageLocale"
            :options="languages"
            deselect-label="Can't remove this value"
            track-by="locale"
            label="name"
            :allow-empty="false"
        />
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "LanguageLocaleField",
    data() {
        return {
            languages: []
        }
    },
    computed: {
        ...mapState({
            languageLocale: state => state.Locale.currentLocale,
            companyData: state => state.Company.data
        }),
        currentLenguage() {
            const companySetting = this.companyData.settings;
            const { value } = companySetting.find(({ name }) => name === "region_default_language");
            return value;
        },
        selectedLanguageLocale: {
            get() {
                return this.languageLocale;
            },
            set(newLocale) {
                this.setCurrentLocale(newLocale);
            }
        }
    },
    async mounted() {
        await this.fetchLanguages();
        const selectedLanguages = this.languages.find(({ locale }) => locale === this.currentLenguage);
        this.setCurrentLocale(selectedLanguages);
    },
    methods: {
        ...mapActions({
            setCurrentLocale: "Locale/setCurrentLocale"
        }),
        async fetchLanguages() {
            await axios.get("/languages?q=(is_enabled:1)")
                .then((response) => {
                    this.languages = response.data;
                    this.selectedLanguage = this.languages.find((lang => lang.locale === this.defaultLocale))
                }).catch((error) => {
                    console.log(error)
                })
        }
    }
}
</script>

<style lang="scss">

</style>
