<template>
    <div class="browse-table">
        <gw-browse
            ref="gwBrowse"
            :append-params="appendParams"
            :http-options="{ baseURL, headers: { Authorization: token }}"
            :pagination-data="paginationData"
            :query-params="queryParams"
            :resource="resource"
            pagination-path=""
            :http-fetch="fetchResource"
            :row-class="rowClass"
            @load-error="loadError"
        >
            <template
                v-slot:actions-delete="props"
            >
                <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    @click="confirmDelete(props)"
                >
                    <i class="fa fa-trash-alt" />
                </button>
            </template>
            <template
                v-slot:actions-edit="props"
            >
                <router-link
                    :to="{ name: 'edit-resource', params: { id: props.rowData.id } }"
                    type="button"
                    class="btn btn-primary btn-sm"
                >
                    <i class="fa fa-edit" />
                </router-link>
            </template>
        </gw-browse>
    </div>
</template>

<script>
const { GwBrowse } = require(`@/import.${process.env.VUE_APP_IMPORTS}`);
import ResourceDeleteModal from "@c/modals/resource-delete-modal";
import { isJson, composeResources } from "@/utils/helpers";
import { mapState } from "vuex";

import { specialResources } from "@/config/constants";

export default {
    name: "Browse",
    components: {
        GwBrowse
    },
    data() {
        return {
            appendParams: {
                format: "true"
            },
            baseURL: process.env.VUE_APP_BASE_API_URL,
            queryParams: {
                sort: "sort",
                page: "page",
                perPage: "limit"
            },
            token: this.$store.state.User.token || Cookies.get("token"),
            defaultLocale: "de_DE"
        }
    },
    computed: {
        ...mapState({
            resources: state => composeResources(state.Application.resources)
        }),
        resource() {
            return this.resources.find(resource => resource.slug == this.$route.params.resource);
        }
    },
    methods: {
        async fetchResource(endpoint, options) {
            const resources = await axios.get(endpoint, options);

            const { data: resource } = resources;

            for (const resourceData of resource.data) {
                for (const key of Object.keys(resourceData)) {
                    if (isJson(resourceData[key])) {
                        const jsonData = JSON.parse(resourceData[key]);
                        const firstKey = Object.keys(jsonData)[0];
                        resourceData[key] = jsonData[this.defaultLocale] || jsonData[firstKey];
                    }
                }
                resourceData["is_published_in_store"] = Number(resourceData.is_published_in_store) ? "Yes" : "No";
                resourceData["is_published_in_bld"] = Number(resourceData.is_published_in_bld) ? "Yes" : "No";
            }

            return resources;
        },
        loadError(error) {
            this.$notify({
                title: "Error",
                text: error.response.data.errors.message,
                type: "error"
            });
        },
        paginationData(data) {
            const paginationData = {
                total: parseInt(data.total_rows),
                per_page: parseInt(data.limit),
                current_page: parseInt(data.page),
                last_page: parseInt(data.total_pages)
            }

            const nextParams = this.$refs.gwBrowse.getAllQueryParams();
            nextParams.page = nextParams.page == paginationData.last_page ? null : nextParams.page + 1;
            const prevParams = this.$refs.gwBrowse.getAllQueryParams();
            prevParams.page = prevParams.page == 1 ? null : prevParams.page - 1;

            const nextQuery = Object.keys(nextParams).map(key => `${key}=${nextParams[key]}`);
            const prevQuery = Object.keys(prevParams).map(key => `${key}=${prevParams[key]}`);

            paginationData.next_page_url = nextParams.page === null ? null : `${this.baseURL}?${nextQuery.join("&")}&format=true`;
            paginationData.prev_page_url = prevParams.page === null ? null : `${this.baseURL}?${prevQuery.join("&")}&format=true`;
            paginationData.from = (paginationData.current_page - 1) * paginationData.per_page + 1;
            paginationData.to = paginationData.from + paginationData.per_page - 1;

            return paginationData;
        },
        confirmDelete(props) {
            this.$modal.show(ResourceDeleteModal, {
                buttons: [{
                    title: "Cancel",
                    handler: () => {
                        this.$modal.hide("delete-resource");
                    }
                }, {
                    title: "Confirm",
                    class: "btn-danger",
                    handler: () => {
                        this.deleteResource(props);
                    }
                }, {
                    title: "Archive",
                    class: "btn-secondary",
                    handler: () => {
                        this.archiveResource(props);
                    }
                }],
                deleteEntity: "this"
            }, {
                adaptive: true,
                clickToClose: false,
                height: "auto",
                name: "delete-resource",
                width: 500
            });
        },
        async deleteResource(props) {
            const path = specialResources.includes(this.$route.params.resource) ? this.$route.params.resource : "products";

            try {
                await axios({
                    url: `/${ path }/${props.rowData.id}`,
                    method: "DELETE"
                })

                this.$notify({
                    text: `${this.resource.name} was deleted successfully.`,
                    type: "success"
                });

                this.$refs.gwBrowse.refresh();
            } catch (error) {
                this.$notify({
                    text: error.response.data.errors.message,
                    type: "error"
                });
            } finally {
                this.$modal.hide("delete-resource");
            }
        },
        async archiveResource(props) {
            const path = specialResources.includes(this.$route.params.resource) ? this.$route.params.resource : "products";

            try {
                await axios({
                    url: `/${path}/${props.rowData.id}`,
                    method: "PUT",
                    body: JSON.stringify({
                        is_published: 0
                    })
                })

                this.$notify({
                    text: `${this.resource.name} was archived successfully.`,
                    type: "success"
                });

                this.$refs.gwBrowse.refresh();
            } catch (error) {
                this.$notify({
                    text: error.response.data.errors.message,
                    type: "error"
                });
            } finally {
                this.$modal.hide("delete-resource");
            }
        },
        rowClass(rowData) {
            let classNames = "";

            if (rowData.is_published_in_store.toLowerCase() === "yes") {
                classNames += "is-published-in-store "
            } else {
                classNames += "is-not-published-in-store "
            }

            if (rowData.is_published_in_bld.toLowerCase() === "yes") {
                classNames += "is-published-in-bld "
            } else {
                classNames += "is-not-published-in-bld "
            }

            if (Number(rowData.is_published) === 1) {
                classNames += "is-published"
            } else {
                classNames += "is-not-published"
            }

            return classNames;
        }
    }
}
</script>

<style lang="scss">
.browse-table {
    .table-container {
        border: 0;
        box-shadow: 0 7px 14px rgba(132, 132, 132, 0.15);
        padding: 20px;
        border-radius: 5px;
        background-color: white;

        .vuetable-body {
            tr:nth-child(2n+2) {
                background-color: #F9F9F9;
            }

            tr:hover {
                background-color: #EEEEEE;
            }

            .is-published-in-store .vuetable-td-is_published_in_store,
            .is-published-in-bld .vuetable-td-is_published_in_bld,
            .is-published .vuetable-td-is_published {
                border-bottom: solid 7px #2bb52b;
            }

            .is-not-published-in-store .vuetable-td-is_published_in_store,
            .is-not-published-in-bld .vuetable-td-is_published_in_bld,
            .is-not-published .vuetable-td-is_published {
                border-bottom: solid 7px #C82333;
            }
        }
    }
}
</style>
