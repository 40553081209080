<template>
    <div class="form-actions">
        <div class="row m-2">
            <slot name="cancel-button">
                <button
                    :disabled="isLoading"
                    :title="isLoading ? 'Processing, wait a moment...' : 'Cancel'"
                    class="btn m-1 btn-danger float-right"
                    type="button"
                    @click="$emit('cancel')"
                >
                    <slot name="cancel-label">
                        Cancel
                    </slot>
                </button>
            </slot>
            <slot name="save-button">
                <button
                    :disabled="isLoading"
                    :title="isLoading ? 'Processing, wait a moment...' : 'Save'"
                    :class="{ 'deactivated': isLoading }"
                    type="submit"
                    class="btn m-1 btn-primary float-right"
                    @click="() => $emit('save')"
                >
                    <slot name="submit-label">
                        Submit
                    </slot>
                </button>
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isLoading: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.form-actions {
    display: flex;
    justify-content: flex-end;
    button {
        width: 138px;
    }
}
</style>
